.participant-summary {
	display: flex;
	align-items: center;

	> .total {
		.font-size-body-large;
		margin-right: 1.5rem;
	}

	+ .description {
		margin-top: 1.5rem;

		@media @vp-sm-down {
			.font-size-body-small;
		}
	}
}