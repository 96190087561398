.status-slug {
	display: inline-block;
	vertical-align: middle;
	text-decoration: none;
	.button-size-default;
	.fw-black;
	border-radius: 5px;
	
	&.-open {
		background: #DCFADB;
		color: darken(@green, 10%);
	}

	&.-draft {
		background: @background;
		color: @brand;
	}

	&.-closed {
		background: #FCE3E2;
		color: @red;
	}

	&.-ready,
	&.-scheduled {
		background: @green;
		color: white;
	}

	&.-scheduled {
		padding-left: 24px;
		position: relative;

		&.-small { padding-left: 26px; }

		&:before {
			content: "";
			display: block;
			position: absolute;
			top: 50%;
			left: 6px;
			width: 16px;
			height: 16px;
			line-height: 1;
			background-size: 100% 100%;
			background-repeat: no-repeat;
			background-image: url("../img/icon/white/time.svg");
			transform: translateY(-9px);
		}
	}

	&.-small {
		.button-size-small;

		@media @vp-sm-down {
			padding: unit((2 / 10), em) 0.75em unit((3 / 10), em);
		}
	}
}