.survey-question {
	position: relative;
	padding-top: 3.5rem;

	@media @vp-sm-down {
		padding-top: 2rem;
		padding-bottom: 2rem;
	}

	&:before {
		.positioned-border-responsive;
		top: 0;
	}

	> .subheading-text {
		.col-gutters-responsive;
		position: relative;
		margin-bottom: 2rem;

		@media @vp-sm-down {
			.font-size-label-large;
			margin-bottom: 2rem;
		}

		> .number {
			@media @vp-md-up {
				display: block;
				position: absolute;
				top: 0;
				right: 100%;
				text-align: right;
			}
		}

		> .optional {
			.font-size-body-small;
			color: @body-light;
			.fw-medium;
			font-style: italic;
		}

		> .body-text {
			display: inline-block;
		}

		+ .question-subtitle {
			.col-gutters-responsive;
			margin-top: -1rem;
			margin-bottom: 2rem;
			font-style: italic;
		}  
	}

	> .form-row {
		display: block;

		> .option-inputs,
		> .form-input {
			.col-gutters-responsive;
		}

		&.-scale {
			padding-left: 12px;
			padding-right: 12px;
		}
	}

	> .footer-actions {
		@media @vp-md-up {
			margin-top: 1.5rem;
		}

		@media @vp-sm-down {
			position: fixed;
			bottom: 0;
			left: 0;
			width: 100%;
			padding-top: 0.75rem;
			padding-bottom: 0.75rem;
			background: white;
			box-shadow: 0 0 8px fade(@brand, 15%);

			&:before { display: none; }
		}
	}
}