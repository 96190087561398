.loading-container {
	padding-top: 2rem;
	padding-bottom: 2rem;
	.col-gutters-responsive;

	&.-centered {
		text-align: center;
	}
	
	&.-large {
		> .loading-spinner {
			width: 80px;
			height: 80px;
		}
	}
}