.knowledge-base {
	@media @vp-md-up {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
	}

	> .headline-text {
		.col-gutters-responsive;
		
		@media @vp-md-up {
			width: 100%;
		}
	}

	> .side-menu {
		.col-gutters-responsive;

		@media @vp-md-up {
			.cols(3, 10);
			margin-top: 0.75rem; // Because it sits a bit higher than the content title
		}

		@media @vp-sm-down {
			margin-top: -1rem;
			margin-bottom: 2rem;
		}

		> .menutrigger {
			.fw-bold;
			margin-bottom: 0.5rem;

			@media @vp-md-up {
				display: none;
			}
		}

		> .trigger {
			@media @vp-md-up {
				display: none;
			}

			@media @vp-sm-down {
				position: absolute;
				width: 1px;
				height: 1px;
				padding: 0;
				margin: -1px;
				overflow: hidden;
				clip: rect(0,0,0,0);
				border: 0;

				~ .menu {
					display: none;
				}

				&:checked {
					~ .menu {
						display: block;
					}

					~ .menutrigger {
						&:before {
							transform: rotate(90deg);
						}
					}
				}
			}
		}
	}

	> .content {
		.col-gutters-responsive;
		
		@media @vp-md-up {
			.cols(6, 10);
		}

		@media @vp-lg-up {
			margin-right: (@grid-col-gutter * 2);
		}

		> * + .resource-summaries {
			margin-top: 3rem;
		}

		> .headline-text {
			margin-bottom: 1rem;
		}

		> .media {
			margin: 1rem 0 1.5rem;

			img {
				display: block;
				max-width: 100%;
				height: auto;
			}

			div[data-vimeo-initialized] {
				margin-top: -0.5rem;
			}
		}

		.title {
			&.-link {
				cursor: pointer;
			}
		}

		> .related-articles {
			margin-top: 3rem;

			> .articles {
				margin-top: 2rem;
				
				> .content-teaser {
					+ .content-teaser {
						margin-top: 1.5rem;
					}
				}
			}
		}
	}
}