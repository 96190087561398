.button-size-default() {
	.font-size-button;
	padding: unit((8 / 14), em) 1.5em unit((8 / 14), em);
}

.button-size-hero() {
	.font-size-button-hero;
	padding: unit((16 / @rem-base), em) 2em unit((16 / @rem-base), em);
}

.button-size-small() {
	.font-size-button-small;
	padding: unit((5 / 10), em) 1em unit((6 / 10), em);
}

.button-size-xsmall() {
	.font-size-button-xsmall;
	padding: unit((5 / 10), em) 1.5em unit((6 / 10), em);
}