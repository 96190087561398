.form-input {
	> .field:not(.rdt),
	> .field input[type="text"],
	> .select {
		box-sizing: border-box;
		.font-size-field;
		font-family: inherit;
	}
	
	&.-text,
	&.-textarea,
	&.-search,
	&.-date,
	&.-time {
		> .field:not(.rdt),
		> .field input[type="text"] {
			.field-defaults;
		}
	}
	
	&.-textarea {
		> .field {
			display: block;
			min-height: unit(((18 + (24 * 4)) / 16), em);
		}
	}
	
	&.-text {
		> .field {
			&.-small {
				.font-size-field-small;
				padding-top: unit((6 / 14), em);
				padding-bottom: unit((6 / 14), em);
			}
		}

		> .option-inputs:first-child {
			margin-top: unit((11 / @rem-base), rem);
			margin-bottom: 0.5rem;
		}
	}
	
	&.-textarea,
	&.-text {
		> .field {
			&:disabled {
				opacity: 0.5;
				color: inherit;
			}
		}
	}
	
	&.-search,
	&.-select,
	&.-date {
		> .field:not(.rdt),
		> .field input[type="text"],
		> .select {
			background-position: 100% 50%;
			background-repeat: no-repeat;
			padding: unit((8 / 16), em) unit((32 / 16), em) unit((8 / 16), em) unit((8 / 16), em);
			
			&.-small {
				.font-size-field-small;
				padding-top: unit((6 / 16), em);
				padding-bottom: unit((6 / 16), em);
			}

			&:disabled {
				opacity: 0.5;
				color: inherit;
			}
		}
	}
	
	&.-search {
		> .field {
			background-image: url("../img/field-search.svg");
			background-position: 0% 50%;
			background-size: 32px 24px;
			padding: unit((8 / 16), em) unit((8 / 16), em) unit((8 / 16), em) unit((32 / 16), em);
		}
	}

	&.-date {
		position: relative;

		> .clear {
			&:hover {
				cursor: pointer;

				&:before {
					opacity: 0.3;
				}
			}

			&:before {
				content: '';
				background-image: url("../img/icon/close.svg");
				position: absolute;
				width: 18px;
				height: 18px;
				background-size: 100%;
				top: 11px;
				right: 48px;
				opacity: 0.6;
			}
		}

		> .field:not(.rdt),
		> .field input[type="text"] {
			background-image: url("../img/field-date.svg");
			background-size: 24px 24px;

			&:disabled {
				opacity: 0.5;
				color: inherit;
			}
		}
	}

	&.-time {
		> .field {
			max-width: 80px;

			&:disabled {
				opacity: 0.5;
				color: inherit;
			}
		}
	}
	
	&.-select {
		> .select {
			display: block;
			cursor: pointer;
			.field-defaults;
			background-image: url("../img/field-select.svg");
			background-size: 24px 24px;
			padding-right: unit((24 / 16), em);
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
			
			&.-small {
				.font-size-field-small;
				padding-top: unit((6 / 14), em);
				padding-bottom: unit((6 / 14), em);
				background-image: url("../img/field-select-small.svg");
			}
			
			&:-ms-expand { display: none; }
			&::-ms-expand { display: none; }
			
			option[data-default] {
				color: @brand;
			}
		}
	}
	
	&.-textonly {
		width: 100%;
		.font-size-body;
		padding: unit((8 / 16), em) unit((8 / 16), em);
		color: @brand;

		> p { margin: 0; }
	}
	
	// &.-file {
	// 	display: flex;
		
	// 	> .file {
	// 		.js & {
	// 			width: 0.1px;
	// 			height: 0.1px;
	// 			opacity: 0;
	// 			overflow: hidden;
	// 			position: absolute;
	// 			z-index: -1;
	// 		}
	// 	}

	> .loading-spinner {
		margin-top: 8px;
		margin-bottom: 8px;
	}

	> .note {
		.font-size-body-small;

		+ .option-inputs { margin-top: 0.5rem; }
	}

	> * + .note {
		margin-top: 0.5rem;
		margin-bottom: 0;
	}

	.ql-editor {
		padding: 0;

		&[contenteditable=false] {
			color: @brand-50;
		}
	}

	> .option-inputs {
		.label.-bold {
			margin-bottom: 0.75rem;
		}		
	}

	> .logo {
		position: relative;
		
		> .aspect {
			height: 0;
			width: 100%;
			position: relative;
			
			> img {
				display: block;
				width: 100%;
				height: 100%;
				position: absolute;
				top: 0;
				left: 0;
			}
		}

		> .remove {
			position: absolute;
			right: 0;

			> .icon-text.-close {
				z-index: 1;
			}
		}
	}
}