.statistic-highlight {
	> .header {
		margin-bottom: 1rem;

		> .type {
			display: inline-block;
			.font-small-uppercase;
			line-height: 1;
			margin-bottom: 0.25rem;
			position: relative;
			padding-right: 14px;

			> .arrow {
				display: block;
				.hide-text;
				position: absolute;
				right: 0;
				top: -3px;
				width: 10px;
				height: 16px;
				// transform: translateY(-50%);
				background-size: 100% 100%;
				background-repeat: no-repeat;
			}

			&.-best {
				color: @green;

				> .arrow { background-image: url("../img/arrow-best.svg"); }
			}

			&.-worst {
				color: @red;

				> .arrow { background-image: url("../img/arrow-worst.svg"); }
			}
		}

		> .title {
			display: block;
			.fw-bold;
		}
	}

	> .bar-chart {
		margin-bottom: 0.5rem;
	}

	> .body-text {
		color: @brand-70;
		margin-bottom: 0;
	}
}