.filter-options {
	padding-left: 0;
	margin: 0;
	list-style: none;
	padding-left: (@grid-col-gutter / 2);
	padding-right: @grid-col-gutter;
	
	> .option {
		white-space: nowrap;
		cursor: pointer;

		&:before {
			content: "";
			display: inline-block;
			vertical-align: middle;
			width: 20px;
			height: 20px;
			background-size: 100% 100%;
			background-repeat: no-repeat;
			background-image: url("../img/icon/right.svg");
			opacity: 0.7;
			position: relative;
			top: -2px;
			margin-right: 4px;
		}

		> .option-inputs {
			margin-left: 1.5rem;
			padding-bottom: 1rem;
			display: none;
		}

		&.-active {
			&:before {
				background-image: url("../img/icon/down.svg");
			}

			> .option-inputs { display: block; }
		}
	}
}