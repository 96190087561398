.screen-report {
	> .report-overview {
		@media @vp-md-up {
			margin-left: -@grid-col-gutter;
			margin-right: -@grid-col-gutter;
		}

		@media @vp-sm-down {
			margin-left: 0;
			margin-right: 0;
		}

		@media screen and @vp-md {
			border-radius: 0;
		}

		+ .body-text {
			@media print {
				page-break-after: always;
			}	
		}
	}

	> .body-text {
		.col-gutters-responsive;

		+ .overview-columns { margin-top: 3rem; }

		> .summaryinput {
			> .form-field { padding-bottom: 0.5rem; }
			> .button-link {
				display: block;
				margin-left: auto;
			}
		}
	}

	> .overview-columns {
		@media print { display: none; }
	}

	> .chart-key {
		margin-bottom: 1.5rem;
	}

	> .intro {
		margin-bottom: 2rem;
		padding-bottom: 2rem;
		position: relative;

		&:after {
			.positioned-border-responsive;
			bottom: 0;
			height: 1px;
			background: @border;
		}

		@media @vp-md-up {
			display: flex;
			justify-content: space-between;
			padding-bottom: 3rem;
			margin-bottom: 3rem;
		}

		@media print {
			display: flex;
			justify-content: space-between;
			padding-bottom: 3rem;
			margin-bottom: 3rem;
		}

		> .body-text {
			.col-gutters-responsive;

			@media @vp-md-up {
				.cols(4.5, 8);
			}
			
			@media print {
				.cols(4.5, 8);
				.font-size-body;
			}

			@media @vp-sm-down {
				&.-large {
					.font-size-body;
				}
			}
		}
		
		> .chart {
			.col-gutters-responsive;

			> .chart-key {
				margin-top: 1.5rem;
			}

			@media @vp-md-up { .cols(3, 8); }
			@media @vp-sm-down {
				.cols(10);
				margin-right: auto;
				margin-left: auto;
				margin-top: 1.5rem;
			}
			@media print { .cols(3, 8); }

		}
	}

	> .button-group {
		.col-gutters-responsive;

		@media @vp-md-up {
			margin-top: 4rem;
		}
	}

	> .errors {
		.col-gutters-responsive;
		padding-top: 1rem;
		padding-bottom: 1rem;
	}

	> .results-block {
		@media @vp-sm-down {
			&:not(.report-overview) {
				margin-left: -(@page-gutters-sm);
				margin-right: -(@page-gutters-sm);
				padding-left: @page-gutters-sm;
				padding-right: @page-gutters-sm;
				padding-top: 2rem;
				box-shadow: none;
				border-top: 1px solid @border;
				border-radius: 0;
			}
		}

		@media @vp-md-down {
			&:not(.report-overview) {
				box-shadow: none;
			}
		}

		@media @vp-md {
			margin-left: -(@page-gutters-md);
			margin-right: -(@page-gutters-md);
			padding-left: @page-gutters-md;
			padding-right: @page-gutters-md;

			+ .results-block {
				border-top: 1px solid @border;
				padding-top: 3rem;
			}

			> .content {
				.col-gutters;
			}

			&.report-overview {
				padding-left: 0;
				padding-right: 0;
			}
		}

		@media print {
			page-break-after: always;
			padding-top: 0;
			margin-bottom: 3rem;
			
			&:not(.report-overview) {
				border-top: none;
			}
			
			&.report-overview {
				page-break-after: initial;
			}
		}

		> .content {
			@media @vp-sm-down {
				margin-left: 0;
				margin-right: 0;
				padding-top: 0;
				.col-gutters-responsive;
			}
		}

		&.report-overview {
			@media @vp-sm-down {
				.col-gutters-as-margin-sm;
			}
		}	
	}
}