.edit-participants {
	.col-gutters-responsive;
	
	> .header {
		margin-bottom: 1rem;
		display: flex;

		> .form-input.-search {
			.cols(3, 6);
		}

		> .button-group {
			margin-left: auto;

			.icon-text + .icon-text { margin-left: 1rem; }
		}
		
		.icon-text {
			cursor: pointer;
			margin-top: 0;
			margin-bottom: 0;
		}
	}

	> .footer-actions {
		.col-gutters-negate-responsive;
		margin-top: 0;

		> .pagination-block {
			.col-gutters-responsive;
			margin-top: 0;

			@media @vp-sm-down {
				margin-bottom: 1rem;
			}		
		}
	}

	@media @vp-sm-down {
		.tablewrapper {
			overflow-x: auto;
			margin-right: -(@page-gutters-sm + @grid-col-gutter-sm);
			margin-left: -(@page-gutters-sm + @grid-col-gutter-sm);
			padding-left: @page-gutters-sm + @grid-col-gutter-sm;
		}
	}

	.data-table {
		display: table;

		> thead th {
			.font-size(8);
			line-height: (12 / 8);
			white-space: nowrap;
		}
		
		> tbody {
			> tr {
				display: table-row;
			}
		}
	}
}