// Customisation of vendor date picker component
.rdtPicker {
	box-shadow: 0 7px 25px fade(@brand, 25%), 0 2px 10px fade(@brand, 10%);
	border-radius: 4px;

	thead {
		tr:first-child {
			th:hover {
				background: @background;
			}
		}
	}

	td, th {
		.font-size-body-small;
	}

	th.rdtSwitch {	
		.fw-bold;
		width: 192px;
	}

	.dow {
		.fw-bold;
	}

	td.rdtToday {
		&:before {
			border-bottom-color: @brand;
			bottom: 0;
			right: 0;
		}
	}

	td.rdtDay.-selected {
		background: fade(@brand-secondary, 35%);
	}

	td.rdtDay,
	td.rdtHour,
	td.rdtMinute,
	td.rdtSecond,
	.rdtTimeToggle {
		.fw-regular;

		&:hover {
			background: @background;
		}
	}

	td.rdtDisabled {
		color: @brand-50;

		&:hover {
			background: none;
		}
	}

	.rdtNext,
	.rdtPrev {
		width: 24px;

		span {
			display: block;
			.hide-text;
			width: 100%;
			height: 100%;
			position: relative;
			opacity: 0.7;

			&:before {
				content: "";
				display: block;
				width: 24px;
				height: 24px;
				position: absolute;
				top: 0;
				background-size: 100% 100%;
				background-repeat: no-repeat;
			}
		}

		&:hover {
			span { opacity: 1; }
		}
	}

	.rdtPrev span:before {
		background-image: url("../img/icon/left.svg");
		left: 0;
	}
	.rdtNext span:before {
		background-image: url("../img/icon/right.svg");
		right: 0;
	}
}