.popup-menu {
	background: white;
	border-radius: @border-radius;
	box-shadow: 0 7px 25px fade(@brand, 25%), 0 2px 10px fade(@brand, 10%);

	> .menuitems {
		padding-left: 0;
		list-style: none;
		padding-top: 0.5rem;
		padding-bottom: 0.5rem;
		margin: 0;
		min-width: 100px;

		&.-contentfit {
			min-width: auto;
		}

		> .menuitem {
			padding-top: 0.25rem;
			padding-bottom: 0.25rem;
			padding-left: 1rem;
			padding-right: 1rem;
			text-align: center;
			margin: 0;
			.font-size-body-small;
			white-space: nowrap;

			> .link {
				display: inline-block;

				&:hover {
					border-bottom: 1px solid @brand-35;
					margin-bottom: -1px;
				}
			}

			+ .-subheading {
				padding-top: 0.75rem;
				margin-top: 0.75rem;
				position: relative;

				&:before {
					content: "";
					display: block;
					height: 1px;
					background: @border;
					position: absolute;
					top: 0;
					left: 0.75rem;
					right: 0.75rem;
				}
				
				> .ui-heading {
					margin-bottom: 0;
					color: @brand-50;

					+ .buttoned-field { margin-top: 0.5rem; }
				}

				> .buttoned-field { max-width: 300px; } 
			}
		}
	}

	> .ui-heading {
		padding-left: 1rem;
		padding-right: 1rem;
		padding-top: 0.75rem;

		+ .buttoned-field { margin-top: 0.5rem; }
	}

	> .buttoned-field { max-width: 200px; } 

	> .option-inputs {
		padding-left: 1rem;
		padding-right: 1rem;
		padding-bottom: 1rem;
	}

	&.-account {
		> .menuitems {
			> .menuitem {
				text-align: left;
				// padding-left: 2rem;
				// padding-right: 2rem;

				+ .-subheading {

				}

				&.-signout {
					margin-bottom: -0.5rem;
					margin-top: 0.75rem;
					padding-top: 0.5rem;
					padding-bottom: 0.75rem;
					position: relative;
					background: @background;
					border-bottom-left-radius: @border-radius;
					border-bottom-right-radius: @border-radius;
					
					// &:before {
					// 	content: "";
					// 	display: block;
					// 	height: 1px;
					// 	background: @border;
					// 	position: absolute;
					// 	top: 0;
					// 	left: 1rem;
					// 	right: 1rem;
					// }
	
					a {
						// color: @red;
					}
				}

				&.-signedin {
					padding-top: 0.5rem;
					padding-bottom: 0.75rem;
					position: relative;
					color: #666;
				}
	
				&.-selected {
					> a {
						.fw-bold;
					}

					&:after {
						content: "";
						display: inline-block;
						vertical-align: middle;
						position: relative;
						top: -1px;
						margin-left: 0.25rem;
						width: 24px;
						height: 24px;
						background-size: 100% 100%;
						background-repeat: no-repeat;
						opacity: 0.7;
						background-image: url("../img/icon/tick.svg");
					}
				}
			}
		}
	}

	&.-filters {
		> .form-input {
			padding-left: 1rem;
			padding-right: 1rem;
		}

		> .footer-actions {
			margin-top: 0;
			// padding-top: 0.5rem;
			min-width: 250px;

			> .button-group {
				width: 100%;
				padding-left: 1rem;
				padding-right: 1rem;
			}
		}
	}
}

.react-tiny-popover-container {
	overflow: visible !important;
	z-index: 10;
}