.modal-contents {
	position: relative;
	transform: translate(0); // Used to trap positioning context for fixed div.control-panel
	
	@media @vp-md-up {
		padding-bottom: 2em;
		.cols(8, 8);
		.contents.-large {
			padding-bottom: 70px;
		}
	}
	
	.modal-layout {
		position: relative;
		max-height: inherit;
		box-sizing: content-box;
		padding-top: 2em;

		.contents.-large & {
			padding-top: 0;
		}

		>.form {
			.col-gutters;
		}

		> .table-layout {
			.col-gutters;
			padding-bottom: 100px;
		}

		> .control-panel {
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 0 (@grid-col-gutter * 2);
			box-sizing: content-box;
			border-bottom-left-radius: @border-radius-large;
			border-bottom-right-radius: @border-radius-large;
			&.-fixed {
				.col-gutters-negate;
				position: fixed;
				bottom: 0;
				left: 0;
				right: 0;
				height: 90px;
				background: white;
				box-shadow: 2px -2px 10px rgba(@grey-dark, 0.2);
			}
			> .controls {
				display: flex;
				align-items: center;
				> .label {
					margin-bottom: 0;
					margin-right: unit((20/@rem-base), rem);
				}
				> .option-inputs {
					display: flex;
					align-items: center;
					> .option {
						margin-right: unit((18/@rem-base), rem);
					}
				}
			}
			> .button-group {
				padding: 0;
				> .button-link {
					+ .button-link {
						margin-left: unit((15/@rem-base), rem);
					}
				}
			}
		}
	}

	.content {
		.text + .form-field {
			margin-top: 1em;
		}
	}

	.contents {
		padding-left: 0.2em;
		padding-right: 0.2em;
	}
	
	.contents.-narrow & {
		width: auto;
	}
	
	.contents.-medium & {
		@media @vp-md-up {
			width: auto;
			margin-left: -@grid-col-gutter;
			margin-right: -@grid-col-gutter;
		}
	}
	
	> .bookinginfo {
		> .button-group {
			padding-bottom: 0;
			padding-top: 0;
		}
	}
	
	> .invitation {
		.form-layout {
			.body-text {
				margin-bottom: 2rem;
			}
		}
	}
	
	.form-layout {
		> .row {
			> .button-group { 
				padding-bottom: 0; 
				margin: 0;
				
				> .button:not(.-gutters) {
					padding: 0;
				}
			}
		}
	}
}
