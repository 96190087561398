.content-cols {
	> .body {
		> .introduction {
			.col-gutters-responsive;
			margin-bottom: 3rem;

			@media @vp-sm-down {
				.font-size-body;
				margin-bottom: 2rem;
			}
		}
	}

	> .aside {
		* + .groups-added { margin-top: 1.5rem; }
	}

	@media @vp-md-up {
		display: flex;

		> .body {
			.cols(7, 10);
		}

		> .aside {
			.cols(2, 10);
			margin-left: percentage((1 /10));
		}

		&.-widesidebar {
			> .body {
				.cols(6.5, 10);
			}
	
			> .aside {
				.cols(3, 10);
				margin-left: percentage((0.5 /10));
			}	
		}
	}
}