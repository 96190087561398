.icon-grid {
	padding-left: 0;
	list-style: none;
	margin: 0;
	display: flex;
	flex-wrap: wrap;
	margin-left: -0.5rem;
	margin-right: -0.5rem;

	> .item {
		.font-size-body-small;
		width: percentage((1 / 3));
		padding: 0.5rem;

		@media @vp-xs {
			width: percentage((1 / 2));
		}

		> .icon {
			background: white;
			box-shadow: 0 2px 25px fade(@brand-35, 50%), 0 1px 10px fade(@brand-35, 25%);
			border-radius: 7px;
			text-align: center;
			padding: 0.75rem 0.5rem 1rem 0.5rem;

			> .icon-unit,
			> .icon-svg {
				width: 48px;
				height: 48px;
			}
	
			> .label {
				display: block;
				width: 100%;
			}
		}
	}

	&.-unframed {
		> .item {
			> .icon {
				box-shadow: none;
				padding: 0;
			}
		}
	}

	&.-horizontal {
		> .item {
			.font-size-body;
			
			> .icon {
				display: flex;
				align-items: center;
				padding: 0;
				text-align: left;

				> .icon-unit,
				> .icon-svg {
					width: 32px;
					height: 32px;
					margin-right: 0.5rem;
					flex-shrink: 0;
					flex-grow: 0;
				}
			}
		}
	}

	&.-small {
		> .item {
			.font-size-body-small;
			.fw-bold;
			
			> .icon {
				display: flex;
				align-items: center;
				padding: 0;
				text-align: left;

				> .icon-unit,
				> .icon-svg {
					width: 24px;
					height: 24px;
					margin-right: 4px;
					flex-shrink: 0;
					flex-grow: 0;
				}
			}
		}
	}
}