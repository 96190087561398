.option-input(
	@size: 24px,
	@bg: @field-bg,
	@bg-hover: @field-bg,
	@bg-focus: @field-bg,
	@border: @border,
	@border-hover: @brand-50,
	@border-focus: @brand-70,
	@border-active: @brand-70,
	@state-indicator-colour: @brand, 
	@state-indicator-colour-active: @brand, 
	@disabled-opacity: 0.35,
	@active-boxshadow: none,
	@text-gap: 8px
) {
	padding-left: (@size + @text-gap);
	position: relative;
	
	> .radio,
	> .checkbox {
		position: absolute;
		left: 0;
		z-index: 0;
		opacity: 0;
		border: none;
		
		+ .substitute {
			display: block;
			position: absolute;
			left: 0;
			top: unit((ceil(0.75 * @size) / @size), em);
			transform: translateY(-50%);
			padding: 0;
			margin: 0;
			width: @size;
			height: @size;
			background-color: @bg;
			border: 1px solid @border;
			box-sizing: border-box;
			// box-shadow: 0 1px 1px fade(@brand-secondary, 35%);
			// box-shadow: 0 1px 2px 0 fade(@brand, 35%);
			
			&:after {
				content: "";
				display: block;
				position: absolute;
				left: 50%;
				top: 50%;
				display: none;
			}
		}
		
		&:checked + .substitute,
		&:active + .substitute {
			+ .substitute {
				border-color: @border-active;
			}
			
			&:after { display: block; }
		}
		
		&:focus {
			+ .substitute {
				// background: @bg-focus;
				border-color: @border-focus;
				// box-shadow: 0 1px 1px fade(@brand-secondary, 50%);
				// box-shadow: 0 1px 2px 0 fade(@brand, 35%);
			}
		
			&:checked + .substitute {
				&:after {
					// border-color: @border-focus;
					// background: @state-indicator-colour-active;
				}
			}
		
			&:disabled,
			&:disabled:checked {
				+ .substitute {
					background-color: @bg;
					border: 1px solid @border;

					// &:after {
					// 	border-color: @border;
					// 	background: @bg;
					// }
				}
			}
		}
		
		&:active {
			&:checked + .substitute {
				&:after {
					border-color: @border-active;
					// background: @state-indicator-colour-active;
				}
			}
		}
		
		&:disabled {
			+ .substitute {
				opacity: @disabled-opacity;
				// box-shadow: none;
				
				&:after {
					border-color: @state-indicator-colour;
				}
			}
			
			&:active + .substitute {
				&:after {
					border-color: @state-indicator-colour;
				}
			}
			
			&:checked + .substitute {
				&:after {
					border-color: @state-indicator-colour;
				}
			}
		}
	}
	
	> .checkbox {
		+ .substitute {
			border-radius: 3px;
			
			&:after {
				width: 13px;
				height: 7px;
				border-bottom: 3px solid @state-indicator-colour;
				border-left: 3px solid @state-indicator-colour;
				transform: translate(-6px, -5px) rotate(-45deg);
			}
		}
	}
	
	> .radio {
		+ .substitute {
			border-radius: 50%;
			
			&:after {
				content: "";
				display: block;
				position: absolute;
				left: 50%;
				top: 50%;
				transform-origin: 50% 50%;
				display: none;
				width: 10px;
				height: 10px;
				transform: translate(-50%, -50%);
				background: @state-indicator-colour;
				border-radius: 50%;
			}
		}
		
		&:disabled {
			+ .substitute {
				&:after {
					background: @state-indicator-colour;
				}
			}
			
			&:active + .substitute {
				&:after {
					background: @state-indicator-colour;
				}
			}
			
			&:checked + .substitute {
				&:after {
					background: @state-indicator-colour-active;
				}
			}
		}
	}
	
	&:hover {
		> .radio,
		> .checkbox {
			+ .substitute {
				// background: @bg-hover;
				border-color: @border-hover;
				// box-shadow: 0 1px 1px fade(@brand-secondary, 50%);
				// box-shadow: 0 1px 2px 0 fade(@brand, 35%);
			}
		
			&:focus,
			&:active,
			&:checked {
				+ .substitute {
					// box-shadow: 0 1px 1px fade(@brand-secondary, 50%);
					// box-shadow: 0 1px 2px 0 fade(@brand, 35%);
				}
			}
		}
	}
}