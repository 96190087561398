.inline-option {
	.option-input(@size: 16px, @text-gap: 0px);
	margin-top: 0;
	margin-bottom: 0;
	width: 18px;
	height: 18px;
	position: relative;

	> .checkbox,
	> .radio {
		+ .substitute {
			top: 0;
			transform: none;
		}
	}

	> .checkbox {
		 + .substitute {
			&:after {
				width: 10px;
				height: 5px;
				border-bottom-width: 2px;
				border-left-width: 2px;
				transform: translate(-5px, -3px) rotate(-50deg);
			}
		 }
	}
}	