.report-filtering {
	background: @background;
	border-radius: @border-radius;
	.col-gutters-as-margin-responsive;
	margin-bottom: 2rem;
	display: flex;
	flex-wrap: wrap;
	align-items: center;

	@media print { background: none; } // Won't print anyway

	> .filters {
		padding: 1rem;
		display: flex;
		align-items: center;
		border-right: 1px solid white;

		@media print {
			padding-left: 0;
			padding-top: 0;
			border: none;
		}

		> .ui-heading {
			margin-bottom: 0;
			margin-right: 0.5rem;
			line-height: 24px;
		}

		> .body-text {
			margin-bottom: 0;
			margin-right: 0.25rem;
			line-height: 24px;
		}

		> .tag-set {
			margin-right: 1rem;
		}

		> .icon-unit {
			cursor: pointer;
			opacity: 0.5;

			&:hover { opacity: 1; }
		}
	}

	> .clear {
		border: none;
		display: block;
		text-decoration: none;
		cursor: pointer;
		background: transparent;
		appearance: none;
		font-family: inherit;
		.font-size-body-small;
		line-height: 24px;
		padding: 0 1rem;
		margin-left: auto;
		justify-self: flex-end;
		position: relative;

		&:after {
			content: "";
			display: block;
			height: 1px;
			background: @brand;
			position: absolute;
			bottom: 2px;
			left: 1rem;
			right: 1rem;
			opacity: 0.35;
		}

		&:hover {
			&:after {
				opacity: 0.8;
			}
		}
	}
}