.results-block {
	.shadowed-block;
	.col-gutters-as-margin-responsive;
	margin-bottom: 2rem;

	.header {
		/* Don't break after the chart header */
		page-break-after: avoid;
	}

	/* Try to ensure we don't only get a little of the bars on a page */
	.barcharts > .chart:nth-child(2) {
		page-break-after: avoid;
	}
	.barcharts > .chart:nth-child(3) {
		page-break-after: avoid;
	}
	.barcharts > .chart:nth-child(4) {
		page-break-after: avoid;
	}
	.barcharts > .chart:nth-child(5) {
		page-break-after: avoid;
	}
	.barcharts > .chart:nth-child(6) {
		page-break-after: avoid;
	}

	.barcharts > .chart.-axis {
		page-break-before: avoid;
	}

	@media print {
		margin-top: 0 !important;
		box-shadow: none;
	}

	> .content {
		@media @vp-lg-up {
			.col-gutters-negate-responsive;
		}
	}

	&.-intro {
		position: relative;

		@media @vp-md-up {
			width: 100%;
		}

		> .content {
			display: flex;
			align-items: center;

			@media @vp-sm-down {
				flex-direction: column;
			}

			> .summary {
				.col-gutters-responsive;
				padding-left: 0;

				@media @vp-md-up {
					.cols(5, 10);
					margin-left: (percentage(0.5 / 10));
					margin-right: (percentage(0.5 / 10));
					padding-top: 2rem;
					padding-bottom: 2rem;
				}

				@media @vp-sm-down {
					order: 2;
					.col-gutters-sm;
					margin-left: (percentage(0.5 / @grid-cols-total));
					margin-right: (percentage(0.5 / @grid-cols-total));
					padding-bottom: 1.5rem;
				}

				.body-text {
					margin-bottom: 0;
					
					@media @vp-sm-down {
						.font-size-body;
					}
				}
				.headline-text {
					margin-bottom: 1.5rem;
					
					@media @vp-sm-down {
						margin-bottom: 1rem;
					}
				}
			}

			> .chart-key {
				.col-gutters-responsive;
				padding-top: 1.5rem;
				padding-bottom: 1.5rem;

				@media @vp-md-up {
					margin-left: auto;
					margin-right: (percentage(0.5 / 10));
				}

				@media @vp-sm-down {
					.cols(10);
					order: 1;
				}
			}

			> .chart {
				.col-gutters-responsive;
				padding-top: 1.5rem;
				padding-bottom: 1.5rem;

				@media @vp-md-up {
					.cols(3, 10);
					margin-right: percentage((1 / @grid-cols-total));
				}

				@media @vp-sm-down {
					.cols(10);
					order: 1;
				}

				&.-narrow {
					@media @vp-md-up {
						.cols(2.5, 10);
						margin-left: auto;

						> .chart-key {
							margin-left: -(percentage((2 / @grid-cols-total)));
							margin-right: -(percentage((2 / @grid-cols-total)));
						}
					}
				}

				> .aspect {
					height: 0;
					padding-top: percentage((165/290));
					position: relative;

					img {
						display: block;
						width: 100%;
						height: 100%;
						position: absolute;
						top: 0;
						left: 0;
					}
				}

				> .chart-key {
					margin-top: 1.5rem;
				}
			}
		}

		&.-compact {
			> .content {
				> .summary {
					@media @vp-md-up {
						.cols(7, 10);
						padding-top: 1.5rem;
						padding-bottom: 1.5rem;
					}
				}
			}

			&.-full {
				width: 100%;
			}
		}

		&.-resource {
			@media @vp-md-up {
				display: flex;
				justify-content: space-between;
				align-items: center;
			}
			
			> .content {
				@media @vp-md-up {
					.cols(5, 10);
					display: block;
					padding-top: 1rem;
					padding-right: @grid-col-gutter;
					padding-left: (percentage(0.5 / 10));
					.col-gutters-negate-responsive;
				}

				> .summary {
					width: 100%;
					margin-left: 0;
					padding-top: 1rem;

					> .chart {
						padding-left: 0;
						width: 60%;
						margin-top: 2rem;
						margin-bottom: 2rem;
					}
				}

				> .subheading-text {
					margin-top: 1.5rem;
					margin-bottom: 1rem;
				}
			}
		}
	}

	&.-usersummary {
		// padding-top: 2rem;
		padding-bottom: 2rem;
	}

	>.body-text.-guttered {
		.col-gutters-as-margin-responsive;	
	}

	>.body-text .note {
		.font-size-body-small;
		color: @body-light;
		display: inline-block;
		line-height: 20px;
		margin-top: 2rem;
	}
}