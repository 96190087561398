.sans-serif() {
	font-family: @sans-font-family;
	.fw-regular;
}

.fw-light() { font-weight: 300; }
.fw-regular() { font-weight: normal; }
.fw-medium() { font-weight: 500; }
.fw-bold() { font-weight: 700; }
.fw-black() { font-weight: 900; }

.spaced-uppercase(@size: @rem-base) {
	text-transform: uppercase;
	letter-spacing: unit((1 / @size), em);
	.fw-black;
}

.font-size-headline() {
	.font-size(32);
	line-height: (40 / 32); 
	// letter-spacing: -(unit((1 / 36), em));
	.fw-bold;
}

.font-size-subheading() {
	.font-size(22);
	line-height: (26 / 22);
	.fw-bold;
}

.font-size-body-hero() {
	.font-size(20); // @fs-body-large
	line-height: (32 / 20);
	.fw-regular;
}

.font-size-body-large() {
	.font-size(18); // @fs-body-large
	line-height: (26 / 18);
	.fw-regular;
}

.font-size-body() {
	.font-size(16); // @fs-body
	line-height: (24 / 16);
	.fw-regular;
}

.font-size-body-small() {
	.font-size(14); // @fs-body-small
	line-height: (20 / 14);
}

.font-size-caption() {
	.font-size(12); // @fs-body-caption
	line-height: (20 / 12);
}

.font-size-metadata() {
	.font-size(12); // @fs-body-metadata
	line-height: (18 / 12);
}

.font-size-ui() {
	.font-size(14);
	line-height: (22 / 14);
}

.font-size-field() {
	.font-size(16);
	line-height: (24 / 16);
	.fw-regular;
}

.font-size-field-small() {
	.font-size(14);
	line-height: (20 / 14);
	.fw-regular;
}

.font-size-button() {
	.font-size(14);
	line-height: (24 / 14);
	.spaced-uppercase(14);
}

.font-size-button-hero() {
	.font-size(@rem-base);
	line-height: (24 / 16);
	.spaced-uppercase(16);
}

.font-size-button-small() {
	.font-size(10);
	line-height: (16 / 10);
	.spaced-uppercase(10);
}

.font-size-button-xsmall() {
	.font-size(10);
	line-height: (18 / 10);
	.spaced-uppercase(10);
}

.font-small-uppercase() {
	.font-size(12);
	.spaced-uppercase(12);
	line-height: (24 / 12);
}

.font-size-label() {
	.font-small-uppercase;
	margin-bottom: 0;
}

.font-size-label-large() {
	.font-size-body-large;
	color: @body;
	.fw-bold;
	margin-bottom: 0;
}

.font-size-nav() {
	.font-size(14);
	line-height: (21 / 14);
	text-transform: uppercase;
	letter-spacing: unit((2 / 14), em);
	.fw-black;
}

.link-style-default() {
	background-image: linear-gradient(to top, @brand-35 0px, @brand-35 1px, fade(white, 0%) 1.1px);

	&:hover {
		background-image: linear-gradient(to top, @brand-70 0px, @brand-70 1px, fade(white, 0%) 1.1px);
	}
}