em { font-style: italic; }

.headline-text {
	.font-size-headline;

	@media @vp-sm-down {
		.font-size(26);
		line-height: (30 / 26);
	}
	
	&.-centered {
		text-align: center;
	}

	&.-hero {
		.font-size(56);
		line-height: 1;

		@media @vp-sm-down {
			.font-size(32);
			line-height: (36 / 32);
		}
	}

	&.-small {
		.font-size(26);
		line-height: (30 / 26);
	}
}

.subheading-text {
	.font-size-subheading;

	page-break-after: avoid;
	
	&.-centered {
		text-align: center;
	}

	&.-small {
		.font-size-label-large;
	}

	&.-underlined {
		border-bottom: 3px solid @brand-secondary;
		padding-bottom: 0.25rem;
	}
}

.body-text {
	.font-size-body;

	@media print {
		.font-size-body-small !important;
	}
	
	p, li {
		margin-top: 0;
		margin-bottom: 0;

		+ p,
		+ li {
			margin-top: 0.5em;
		}

		a {
			.link-style-default;
		}

		strong { .fw-bold; }
	}

	> p {
		strong { .fw-bold; }
	}

	> strong { .fw-bold; }

	> h2,
	> h3 {
		> a {
			.link-style-default;
		}
	}

	> * + .subheading-text {
		margin-top: 2rem;
	}

	.inlinetitle {
		.font-size-label;
		color: @brand-70;
	}

	blockquote {
		padding-left: 2em;
		border-left: 1px solid;
		
		p {
			font-size: inherit;
		}
	}

	&.-large {
		.font-size-body-large;

		@media print {
			.font-size-body;
		}

		>.-link {
			.link-style-default;
		}
	}

	&.-hero {
		.font-size-body-hero;
	}
	
	&.-small {
		.font-size-body-small;

		a {
			.link-style-default;
		}
	}

	&.-centered {
		text-align: center;
	}

	.-left {
		text-align: left;
	}

	.more {
		.link-style-default;
		.font-small-uppercase;
		white-space: nowrap;
	}
}

.caption-text {
	.font-size-caption;
}

.metadata-text {
	.font-size-metadata;
}

.ui-text {
	.font-size-ui;
	.fw-black;
	text-transform: uppercase;
	letter-spacing: unit((1 / @fs-ui), em);
	color: @brand-70;
}

.ui-heading {
	.font-small-uppercase;
	color: @brand-70;

	&.-dark {
		color: @brand;
	}

	&.-large {
		.font-size(14);
		.spaced-uppercase(14);
		line-height: (24 / 14);
	}

	&.-small {
		.font-size(10);
		line-height: (16 / 10);
	}
}

.type-examples {
	.col-gutters();
}