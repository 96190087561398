@import "mixins/button-sizes";
@import "mixins/columns";
@import "mixins/field-defaults";
@import "mixins/font-face";
@import "mixins/font-size";
@import "mixins/font-smoothing";
@import "mixins/gutters";
@import "mixins/hide-text";
@import "mixins/justified-list";
@import "mixins/menu-hamburger";
@import "mixins/option-input";
@import "mixins/screen-reader";
@import "mixins/svg-images";
@import "mixins/tab-focus";
@import "mixins/type";

.icon-image-file(@name) {
	&:before { background-image: url("../img/icon/@{name}.svg"); }
}

.icon-image-file-reversed(@name) {
	&:before { background-image: url("../img/icon/white/@{name}.svg"); }
}

.positioned-border() {
	content: "";
	display: block;
	position: absolute;
	left: @grid-col-gutter;
	right: @grid-col-gutter;
	height: 4px;
	background: mix(@brand-light, white, 25%);
}

.positioned-border-responsive() {
	.positioned-border;

	@media @vp-sm-down {
		left: @grid-col-gutter-sm;
		right: @grid-col-gutter-sm;
	}
}

.scale-label-spacing(@cols) {
	@media @vp-md-up {
		// outline: 1px solid red;
		margin-left: @grid-col-gutter;
		margin-right: @grid-col-gutter;
		padding-left: 0;
		padding-right: 0;
		
		> .key {
			width: percentage((1 / @cols));
			
			&:first-child { margin-left: -(percentage((0.5 / @cols))); }
			&:last-child { margin-right: -(percentage((0.5 / @cols))); }
		}
	}
}

.shadowed-block() {
	background: white;
	box-shadow: 0 2px 25px fade(@brand-35, 50%), 0 1px 10px fade(@brand-35, 25%);
	border-radius: 7px;
}