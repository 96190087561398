.site-header {
	background: @brand;
	border-top: 4px solid @brand-secondary;
	padding-top: env(safe-area-inset-top, 0);

	@media @vp-sm-down {
		border-top-width: 2px;
		min-height: 50px;

		@supports (-webkit-touch-callout: none) {
			border-top-width: 0;
		 }
	}

	@media print {
		border-bottom: 48px solid @brand;

		> .width-limit {
			height: 48px;
			top: 2px;
			position: absolute;
			left: 0;
			width: 100%;
		}
	}

	> .width-limit {
		display: flex;
		justify-content: space-between;
		padding-top: 17px;
		padding-bottom: 17px;

		@media @vp-sm-down {
			padding-top: 10px;
			padding-bottom: 10px;
		}
		
		@media @vp-desktop-nav {
			width: 100%;
		}

		> .site-logo {
			flex-grow: 0;
			flex-shrink: 0;
			.col-gutters-as-margin-responsive;

			@media @vp-sm-down {
				width: 123px;
				height: 26px;
			}

			@media @vp-mobile-nav {
				order: 1;
			}
		}
	
		> .site-nav {
			@media @vp-desktop-nav {
				display: flex;
				margin-left: auto;
				align-self: bottom;
				margin-bottom: -17px;
			}
		}

		> .account-menu {
			display: flex;
			align-items: center;
			
			@media @vp-desktop-nav {
				margin-left: 2em;
			}

			@media @vp-mobile-nav {
				order: 2;
				margin-right: @grid-col-gutter;
				margin-left: auto;
			}

			@media @vp-sm-down {
				margin-right: @grid-col-gutter-sm;
			}
			
			> .icon-text {
				color: white;
				user-select: none;
				margin: 0;
				cursor: pointer;
				
				@media @vp-sm-down {
					.font-size-body-small;
				}
			}
		}
	}
}