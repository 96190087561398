.buttoned-field {
	display: flex;

	> .form-input {
		flex-grow: 1;
	}

	> .button-link {
		flex-grow: 0;
		margin-left: 0.25rem;
	}
}