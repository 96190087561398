.table-header {
	margin-bottom: 1rem;
	display: flex;

	> .form-input.-search {
		.cols(3, 6);
	}

	> .button-group {
		margin-left: auto;

		.icon-text + .icon-text { margin-left: 1rem; }
	}
	
	.icon-text {
		cursor: pointer;
		margin-top: 0;
		margin-bottom: 0;
	}
}