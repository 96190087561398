.bar-chart {
	height: 16px;
	display: flex;
	position: relative;

	> .bar {
		height: 100%;
		border-radius: 8px;
		min-width: 16px;
		overflow: hidden;

		> svg {
			display: block;
			width: 100%;
			height: 100%;

			> path {
				width: 100%;
				height: 100%;
			}
		}

		&.-poor {
			background: @red;

			> svg { fill: @red; }
		}
		&.-good {
			background: @yellow;

			> svg { fill: @yellow; }
		}
		&.-great {
			background: @green;

			> svg { fill: @green; }
		}

		&.-background {
			background: fade(@brand, 10%);
			position: absolute;
			top: 0;
			left: 0;
			z-index: 0;

			> svg { fill: fade(@brand, 10%); }
		}
	}

	&.-background {
		&:before {
			content: "";
			display: block;
			height: 16px;
			border-radius: 16px;
			background: fade(@background, 50%);
			position: absolute;
			z-index: 0;
			top: 0;
			left: 0;
			right: 0;
		}

		> .bar {
			position: relative;

			&.-background { position: absolute; }
		}
	}
}