.pagination-block {
	display: flex;
	justify-content: space-between;
	margin-top: 1.5rem;
	align-items: center;

	> .prevnext {
		width: 24px;

		.icon-unit {
			&:hover { opacity: 1; }
		}
	}

	> .pages {
		display: flex;
		.font-size(14);

		> .page {
			display: block;
			line-height: 24px;
			text-align: center;
			width: 24px;
			height: 24px;
			margin-left: 2px;
			margin-right: 2px;
			flex-shrink: 0;
			flex-grow: 0;
			border-radius: 3px;

			&:hover {
				background: @background;
			}

			&.-selected {
				background: @background;
				.fw-bold;
			}
		}
	}
}