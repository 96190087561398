.two-factor {
	&.form-field {
		margin-top: 1.5rem;
		justify-content: space-between;
		align-items: center;
	}
	> p {
		margin-bottom: 0.5rem;
	}

	.code-container {
		display: flex;
		.form-field {
			margin-top: 15px;
		}
	}
}