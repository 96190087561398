.personal-results {
	@media @vp-md-up {
		display: flex;
		flex-wrap: wrap;
		margin-top: 3rem;
	}
	// .col-gutters-as-margin-responsive;

	> .results-block {
		&.-intro {
			margin-bottom: (@grid-col-gutter * 2);
		}
	}

	> .block {
		@media @vp-md-up {
			width: percentage((5 / 10));
			display: flex;
		}
		
		> .results-block {
			@media @vp-md-up {
				width: 100%;
				display: flex;
				overflow: hidden;
			}

			> .wrap {
				flex-grow: 1;

				@media @vp-md-up {
					display: flex;
					flex-direction: column;
					.col-gutters-negate-responsive;
				}

				> .header {
					width: 100%;
					display: flex;
					flex-wrap: wrap;
					// margin-bottom: 1.5rem;
					padding-top: 1rem;
					// padding-bottom: 1rem;
					align-items: center;

					@media @vp-sm-down {
						padding-left: (percentage(0.5 / @grid-cols-total));
						padding-right: (percentage(0.5 / @grid-cols-total));
					}

					> .icon-unit {
						margin-left: percentage((0.5 / 5));
						position: relative;
						left: -0.5rem;

						@media @vp-sm-down {
							.col-gutters-sm;
							margin-left: 0;
							left: 0;
						}
					}

					> .subheading {
						margin-left: percentage((0.5 / 5));
						margin-top: 1rem;
						.font-size(26);
						line-height: (30 / 26);
						padding-left: 42px;
						padding-left: 0;
						width: 100%;

						@media @vp-sm-down {
							.col-gutters-sm;
							margin-left: 0;
							.font-size-subheading;
						}

						&:before {
							top: -2px;
							opacity: 0.9;
							width: 36px;
							height: 36px;
							display: none;
						}
					}

					> .chart {
						margin-left: auto;
						margin-right: percentage((0.5 / 5));
						width: 100px;

						@media @vp-sm-down {
							margin-right: 0;
						}
					}
				}
				
				> .content {
					display: flex;
					flex-grow: 1;

					@media @vp-sm-down {
						padding-left: (percentage(0.5 / @grid-cols-total));
						padding-right: (percentage(0.5 / @grid-cols-total));
					}

					> .text {
						// .col-gutters-responsive;
						display: flex;
						flex-direction: column;
						
						@media @vp-md-up {
							margin-left: percentage((0.5 / 5));
							margin-right: percentage((0.5 / 5));
						}

						@media @vp-sm-down {
							.col-gutters-sm;
						}
	
						> .button-link {
							margin-top: auto;
							align-self: flex-start;
						}

						> .body-text {
							> .intro {
								margin-bottom: 1em;
							}

							&:not(:last-child) {
								margin-bottom: 2rem;
							}

							+ .button-link { margin-top: auto; }
						}

						> .linklist {
							padding-left: 0;
							list-style: none;
							margin-top: 1rem;
							margin-bottom: 0;

							li {
								+ li { margin-top: 0.5rem; }

								.text {
									.link-style-default;
								}
							}
						}
					}
				}
			}
		}
	}
}