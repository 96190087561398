.menu-toggle {
	display: block;
	color: white;
	.menu-hamburger(
		@target-width: 36px;
		@target-height: 32px;
		@burger-width: 16px;
		@burger-height: 12px;
		@bun-thickness: 2px;
		@include-patty: true;
	);
	
	@media @vp-sm-down {
		margin: -4px 0;
	}

	@media @vp-mobile-nav {
		order: 3;
	}

	@media @vp-desktop-nav { display: none; }
}

#main-nav-menustate {
	position: absolute;
	width: 1px;
	height: 1px;
	padding: 0;
	margin: -1px;
	overflow: hidden;
	clip: rect(0,0,0,0);
	border: 0;

	@media @vp-desktop-nav { display: none; }
}