.overview-columns {
	@media print {
		page-break-after: always;
	}

	> .column {
		> .overview-section {
			@media @vp-lg-up {
				.col-gutters-negate;
			}
		}

		+ .column { margin-top: 2rem; }
	}
}