.participant-groups {
	.col-gutters-responsive;

	.table-header {
		> .button-group {
			
		}
		
		> .form-input {
			&.-search {
				.cols(3);
			}
		}
	}
}