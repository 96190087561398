.participated-surveys {
	margin-top: 3rem;
	
	> .survey {
		border-top: 1px solid @border;
		padding-top: 1rem;
		padding-bottom: 1rem;

		@media @vp-md-up {
			display: flex;
			align-items: center;
			justify-content: space-between;
		}

		@media @vp-sm-down {
			text-align: center;
		}
		
		&:last-child { border-bottom: 1px solid @border; }

		> .details {
			@media @vp-md-up {
				margin-right: 1rem;
			}

			@media @vp-sm-down {
				margin-bottom: 0.5rem;
			}

			> .subheading-text { margin-bottom: 0.25rem; }

			> .body-text {
				margin: 0;
			}
		}

		> .button-link {
			@media @vp-sm-down {
				.button-size-small;

				&.-secondary {
					padding: unit((3 / 10), em) 1em unit((4 / 10), em);
				}
			}
		}
	}
}