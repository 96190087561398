.screen-surveys {
	.surveys {
		@media @vp-md-up {
			.col-gutters;
		}

		@media @vp-sm-down {
			margin-left: -(@page-gutters-sm);
			margin-right: -(@page-gutters-sm);
		}
	}

	.data-table {
		.title {
			.link-style-default;
		}

		@media @vp-sm-down {
			display: block;
			border-top: 4px solid @background;
			padding-top: 1rem;
			padding-bottom: 0.5rem;

			> thead {
				display: none;
			}

			tr {
				display: flex;
				flex-wrap: wrap;
				margin-bottom: 1rem;
				padding-left: (@grid-col-gutter-sm + @page-gutters-sm);
				padding-right: (@grid-col-gutter-sm + @page-gutters-sm);

				+ tr {
					border-top: 1px solid @background;
					padding-top: 1rem;
				}

				&:first-child {
					td, th {
						padding-top: 0;
						padding-bottom: 0;
					}
				}
	
				&:last-child {
					td {
						padding-top: 0;
						padding-bottom: 0;
					}
				}
			}

			th, td {
				padding: 0;
				display: block;
			}

			.title {
				.font-size-body-large;
				.fw-bold;
			}

			.type {
				height: 1px;
				overflow: hidden;
				width: 100%;
				order: 2;
				margin-bottom: 0.25rem;
			}

			.status {
				order: 3;
				margin-right: 0.5rem;
			}

			.modified {
				.font-size-body-small;
				color: @body-light;
				line-height: (26 / 14);
				order: 4;
				margin-right: auto;
			}

			.report {
				order: 5;
			}

			.options {
				order: 6;
				margin-left: 0.5rem;
			}
		}
	}

	.footer {
		margin-top: 2rem;
		.col-gutters-responsive;
	}
}