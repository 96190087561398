.site-logo {
	margin: 0;
	width: 170px;
	height: 36px;
	
	> .link {
		display: block;
		position: relative;
		.hide-text();
		width: 100%;
		height: 0;
		padding-top: percentage((36/170));

		> img {
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;	
		}
	}
}
