.dialog-modal {
	padding-left: 1rem;
	padding-right: 1rem;

	@media @vp-sm-down {
		padding-bottom: 2rem;
	}

	.headline-text {
		.font-size(26);
		line-height: (30 / 26);
	}

	.button-group {
		margin-top: 2rem;
		justify-content: flex-end;
	}
}