.field-defaults() {
	appearance: none;
	width: 100%;
	border: 0;
	border: none;
	border-radius: 5px;
	padding: unit((8 / 16), em) unit((8 / 16), em);
	background-color: @field-bg;
	
	&:focus {
		outline: none;
		box-shadow: inset 0 0 0 1px @brand-50, 0 0 3px @brand;
	}
	
	&::-webkit-input-placeholder { /* Chrome/Opera/Safari */
	  color: @brand-50;
	}
	&::-moz-placeholder { /* Firefox 19+ */
	  color: @brand-50;
	}
	&:-ms-input-placeholder { /* IE 10+ */
	  color: @brand-50;
	}
	&:-moz-placeholder { /* Firefox 18- */
	  color: @brand-50;
	}
}