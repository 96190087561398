.screen-personalreport {
	background-image: url("../img/clouds.jpg");
	background-position: center 70px;
	background-origin: center top;
	background-repeat: repeat-x;

	@media @vp-sm-down {
		background-position: center 50px;
	}
}

.screen-personalreport-detail {
	background-image: url("../img/clouds-short.jpg");
	background-position: center 70px;
	background-origin: center top;
	background-repeat: repeat-x;

	@media @vp-sm-down {
		background-position: center 50px;
	}
}
