.button-link {
	border: none;
	display: inline-block;
	vertical-align: middle;
	text-decoration: none;
	text-transform: uppercase;
	cursor: pointer;
	background: transparent;
	appearance: none;
	font-family: inherit;
	.button-size-default;
	.fw-black;
	
	&.-action,
	&.-destructive,
	&.-secondary,
	&.-confirm {
		border-radius: 5px;
	}

	&:focus {
		outline: none;
		box-shadow: 0 0 3px @brand;
	}
	
	&.-action {
		color: white;
		background: @brand;
		transition: background 0.2s @easeOutQuart;

		&.-next {
			position: relative;
			padding-right: 2.5em;
			background-image: url("../img/icon/button/next-off.svg");
			background-size: 32px 24px;
			background-repeat: no-repeat;
			background-position: 100% 50%;
		}
		
		&:hover {
			background: darken(@brand, 10%);
			
			&[disabled] { background: @brand; }

			&.-next {
				background-image: url("../img/icon/button/next.svg");
				background-repeat: no-repeat;
				background-position: 100% 50%;
			}
		}

		&.-reversed {
			color: white;
			background: @brand;
			
			&:hover {
				background-color: fade(@brand, 90%);
				color: @brand;
			}
			
			// &.-processing {
			// 	color: white;
			//
			// 	&:after { color: @brand-darker; }
			// }
		}
		
		&.-disabled {
			background: @brand;

			&.-next {
				background-image: url("../img/icon/button/next-off.svg");
				background-size: 32px 24px;
				background-repeat: no-repeat;
				background-position: 100% 50%;
			}
		}
	}

	&.-destructive {
		color: white;
		background: @red;
		transition: background 0.2s @easeOutQuart;
		
		&:hover {
			background: darken(@red, 5%);
			
			&[disabled] { background: @red; }
		}
	}
	
	&.-confirm {
		color: white;
		background: @green;
		transition: background 0.2s @easeOutQuart;
		
		&:hover {
			background: darken(@green, 5%);
			
			&[disabled] { background: @green; }
		}
	}
	
	&.-secondary {
		color: fade(@brand, 80%);
		background: white;
		border: 2px solid @brand-light;
		padding: unit((6 / 14), em) 1.5em unit((6 / 14), em);

		&.-back,
		&.-prev {
			position: relative;
			padding-left: 2.5em;
			background-image: url("../img/icon/button/back-off.svg");
			background-size: 32px 24px;
			background-repeat: no-repeat;
			background-position: 0 50%;
		}

		&.-next {
			position: relative;
			padding-right: 2.5em;
			background-image: url("../img/icon/button/secondary/next-off.svg");
			background-size: 32px 24px;
			background-repeat: no-repeat;
			background-position: 100% 50%;
		}
		
		&:hover {
			border-color: @brand-50;
			color: @brand;

			&.-back {
				background-image: url("../img/icon/button/back.svg");
			}

			&.-next {
				background-image: url("../img/icon/button/secondary/next.svg");
			}
			
			&[disabled] { border-color: @brand-light; }
		}
		
		// &.-processing {
		// 	color: @brand-lighter;
		//
		// 	&:after { color: @brand-darker; }
		// }
		
		&.-disabled {
			// background: @brand-lighter;
		}
	}
	
	&.-text {
		color: @brand;
		background: none;
		.font-size-body;
		letter-spacing: 0;
		text-transform: none;
		.fw-regular;
		padding: unit((8 / 16), em) 1.5em;
		color: @brand-50;

		&:hover { color: @brand-70; }
		&:focus { box-shadow: none; }
	}

	&.-hero {
		.button-size-hero;

		&.-secondary {
			padding-top: unit((14 / @rem-base), em);
			padding-bottom: unit((14 / @rem-base), em);
		}
	}
	
	&.-small {
		.button-size-small;

		&.-secondary {
			padding: unit((3 / 10), em) 1em unit((4 / 10), em);
		}
	}
	
	&.-xsmall {
		.button-size-xsmall;
	}
	
	&.-fullwidth {
		width: 100%;
	}

	&.-wide {
		padding-right: 5em;
		padding-left: 5em;
	}
	
	&.-disabled,
	&[disabled] {
		opacity: 0.35;
		cursor: default;
	}
}

@keyframes loading {
	0 {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}