.survey-introduction {
	text-align: center;
	.col-gutters-responsive;

	@media @vp-lg-up {
		min-height: 60vh;
		display: flex;
		flex-direction: column;
		justify-content: center;
	}

	> .logo {
		.cols(4, 6);
		margin-bottom: 2rem;
		margin-left: auto;
		margin-right: auto;
		align-self: center;
		
		@media @vp-sm-down {
			margin-left: auto;
			margin-right: auto;
		}

		> .image {
			> .aspect {
				height: 0;
				position: relative;

				img {
					display: block;
					width: 100%;
					height: 100%;
					position: absolute;
					top: 0;
					left: 0;
				}
			}
		}
	}

	> .headline-text {
		margin-bottom: 2rem;
	}

	> .body-text {
		margin-bottom: 3rem;

		@media @vp-sm-down {
			margin-bottom: 2rem;
			
			&.-large {
				.font-size-body;
			}
		}

		> .status { margin-top: 2rem; }
	}

	> .email-setup {
		width: 100%;
		max-width: 420px;
		margin-left: auto;
		margin-right: auto;
	}

	> .button-link {
		align-self: center;
	}

	> .footer {
		margin-top: 4rem;

		@media @vp-sm-down {
			margin-top: 3rem;
			.font-size-body-small;
		}
	}
}