.breadcrumb-bar {
	background: @background;
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;

	> .width-limit {
		.col-gutters-responsive;

		.icon-text { .fw-bold; }
	}
}