.signin-form {
	max-width: (@grid-col-width * 4);
	margin-left: auto;
	margin-right: auto;

	> .form {
		.col-gutters-responsive;

		> .form-input {
			&.-type {
				margin-bottom: 2rem;
			}

			+ .form-input { margin-top: 1rem; }
		}
	}

	> .footer-actions {
		margin-top: 0;
	}

	&.-wide {
		max-width: (@grid-col-width * 6);

		> .form {
			max-width: (@grid-col-width * 5);
			margin-left: auto;
			margin-right: auto;
		}
	}
}