.content-header {
	margin-bottom: 2rem;
	.col-gutters-responsive;

	> .headline-text {
		margin-bottom: 0;

		+ .body-text.-large {
			margin-top: 1.5rem;

			&.surveytype {
				margin-top: 0.25rem;
				margin-bottom: 3rem;

				@media @vp-sm-down {
					margin-top: 0.5rem;
					margin-bottom: 2rem;
				}
			}

			@media @vp-sm-down {
				.font-size-body;
			}
		}

	 	a {
			.link-style-default;
			.fw-bold;
		}

		+ .button-link {
			@media @vp-sm-down {
				margin-top: 1rem;
			}

			@media @vp-md-up {
				align-self: flex-end;
			}
		}

		.status-slug { margin-left: 0.25rem; }
	}

	> .all {
		.link-style-default;
		.fw-bold;
		align-self: center;
	}
	
	&.-split {
		@media @vp-md-up {
			display: flex;
			justify-content: space-between;
			align-items: flex-start;
		}
	}
}
