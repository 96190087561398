.site-nav {
	align-items: flex-end;

	@media @vp-mobile-nav {
		position: absolute;
		right: 0;
		top: 50px;
		pointer-events: none;
		width: 0;
		width: 100%;
		height: 0;
		overflow: hidden;
		z-index: 1000;
		.col-gutters;
		padding-top: 2rem;

		&:before {
			content: "";
			display: block;
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			z-index: 0;
			background: @brand;
			transition: opacity 0.2s;
			opacity: 0;
		}
	}

	@media @vp-mobile-nav and @vp-sm-down {
		.col-gutters-sm;
	}
	
	> .navitems {
		padding-left: 0;
		margin: 0;

		@media @vp-desktop-nav {
			display: flex;
			flex-wrap: wrap;
			align-items: flex-end;
		}
		
		> .navitem {
			flex: 0 0 auto;
			list-style: none;
			white-space: nowrap;

			@media @vp-desktop-nav {
				.font-size-nav;
			}

			&.-dashboard {
				@media @vp-xs {
					display: none;
				}
			}

			@media @vp-mobile-nav {
				.font-size-body-large;
				padding-left: 0.5rem;
				padding-right: 0.5rem;
				margin-bottom: 1rem;
			}
		
			a {
				display: block;
				text-decoration: none;
				position: relative;

				@media @vp-desktop-nav {
					padding-bottom: 1.5rem;
					padding-left: 0.25rem;
					padding-right: 0.25rem;
					color: fade(white, 80%);

					&:hover {
						color: white;
					}
				}

				@media @vp-mobile-nav {
					color: white;
					padding-left: 1rem;
				}
			}
		
			+ .navitem {
				@media @vp-desktop-nav {
					margin-left: 2em;
				}
			}
		
			&.-selected {
				a {
					color: @brand-secondary;

					@media @vp-desktop-nav {
						background: linear-gradient(to top, currentColor 0px, currentColor 4px, transparent 4px);
					}

					@media @vp-mobile-nav {
						position: relative;

						&:before {
							content: "";
							display: block;
							background: currentColor;
							position: absolute;
							top: 0;
							left: 0;
							width: 2px;
							height: 100%;
						}
					}
				}
			}
		}
	}
}

#main-nav-menustate:checked {
	@media @vp-mobile-nav {
		~ .site-nav {
			height: 100vh;
			height: ~"calc(100vh - 50px)";
			transition+: height 0.25s @easeInOut;
			background-color: @brand;
			pointer-events: initial;
			
			&:before {
				transition: opacity 0.25s @easeOutExpo;
				transition-delay: 0.35s;
			}
		}

		~ .menu-toggle {
			.menu-hamburger-open();
		}

		~ .account-menu {
			display: none;
		}
	}
}