.overview-section {
	padding-top: 2rem;
	padding-bottom: 2rem;
	display: flex;

	@media @vp-sm-down {
		flex-direction: column-reverse;
		padding-top: 1rem;
		padding-bottom: 1rem;
	}

	> .details {
		.col-gutters-responsive;

		@media @vp-md-up {
			.cols(5, 8);
		}
		
		@media @vp-lg-up {
			padding-left: (@grid-col-gutter * 2);
		}
		
		> .headline-text {
			margin-bottom: 1rem;
		}
		
		> .button-link {
			margin-top: 2rem;
			.cols(3, 5);
			text-align: center;
		}
	}


	> .chart {
		.col-gutters-responsive;
		align-self: center;
		
		@media @vp-md-up {
			.cols(3, 8);
		}

		@media @vp-lg-up {
			padding-right: (@grid-col-gutter * 2);
		}
		
		@media @vp-sm-down {
			margin-bottom: 2rem;
			.cols(8);
		}
	}
}