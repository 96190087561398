.icon-text {
	.font-size-body;
	white-space: nowrap;
	display: inline-block;
	padding-left: 28px;
	position: relative;
	appearance: none;
	background-color: transparent;
	border: none;

	&:before {
		content: "";
		display: block;
		position: absolute;
		width: 24px;
		height: 24px;
		background-size: 100% 100%;
		background-repeat: no-repeat;
		left: 0;
		top: 50%;
		margin-top: -12px;
		opacity: 0.7;
	}

	&.-uppercase {
		.font-size(12);
		.spaced-uppercase(12);
		line-height: 24px;

		&:before { margin-top: -13px; }

		&.-smallicon {
			&:before { margin-top: -11px; }
		}
	}

	&.-smallicon {
		padding-left: 24px;

		&:before {
			width: 20px;
			height: 20px;
			margin-top: -10px;
		}	
	}

	&.subheading {
		.font-size-subheading;
		padding-left: 38px;

		&:before {
			width: 32px;
			height: 32px;
			margin-top: 0;
			top: -3px;
			opacity: 0.8;
		}
	}

	&.-add { .icon-image-file("add") }
	&.-account { .icon-image-file-reversed("account") }
	&.-actions { .icon-image-file("actions") }
	&.-alert { .icon-image-file("alert") }
	&.-archive { .icon-image-file("archive") }
	&.-article { .icon-image-file("article") }
	&.-audio { .icon-image-file("audio") }
	&.-back { .icon-image-file("back") }
	&.-balance { .icon-image-file("balance") }
	&.-business { .icon-image-file("business") }
	&.-chart { .icon-image-file("chart") }
	&.-close { .icon-image-file("close") }
	&.-compare { .icon-image-file("compare") }
	&.-confirm { .icon-image-file("confirm") }
	&.-delete { .icon-image-file("delete") }
	&.-download { .icon-image-file("download") }
	&.-edit { .icon-image-file("edit") }
	&.-filter { .icon-image-file("filter") }
	&.-flag { .icon-image-file("flag") }
	&.-go { .icon-image-file("go") }
	&.-health { .icon-image-file("health") }
	&.-help { .icon-image-file("help") }
	&.-left { .icon-image-file("left") }
	&.-more { .icon-image-file("more") }
	&.-order { .icon-image-file("order") }
	&.-person { .icon-image-file("person") }
	&.-piechart { .icon-image-file("piechart") }
	&.-report { .icon-image-file("report") }
	&.-report-combine { .icon-image-file("report-combine") }
	&.-remove { .icon-image-file("remove") }
	&.-resilience { .icon-image-file("resilience") }
	&.-right { .icon-image-file("right") }
	&.-search { .icon-image-file("search") }
	&.-time { .icon-image-file("time") }
	&.-tick { .icon-image-file("tick") }
	&.-upload { .icon-image-file("upload") }
	&.-view { .icon-image-file("view") }
	&.-video { .icon-image-file("video") }
	&.-msgerror { .icon-image-file("message/error") }
	&.-msgsuccess { .icon-image-file("message/success") }
	&.-userhead {
		padding-left: 38px;
		
		.icon-image-file("userhead");

		&:before {
			width: 32px;
			height: 32px;
			margin-top: -16px;
		}
	}
}

a.icon-text,
button.icon-text {
	&:hover {
		&:before { opacity: 1; }
	}

	// &:focus { outline: none; }
	&:focus-visible {
		outline: none;
		box-shadow: inset 0 0 0 1px @brand-50, 0 0 3px @brand;
	}

	&[disabled] {
		opacity: 0.35;
		color: @body;

		&:hover {
			&:before { opacity: 0.7; }
		}
	}
}

button.icon-text,
input.icon-text {
	.sans-serif;
	appearance: none;
	padding-top: 0;
	padding-bottom: 0;
	padding-right: 0;
	cursor: pointer;
}