.result-details {
	margin-bottom: 3rem;
	padding-bottom: 3rem;
	position: relative;
	page-break-after: always;

	&:after {
		.positioned-border-responsive;
		bottom: 0;
		height: 1px;
		background: @border;
	}

	> .content {
		@media @vp-lg-up {
			display: flex;
			flex-wrap: wrap;
			overflow: hidden; // To fix scrolling from chart labels on mobile
		}

		@media @vp-md-down {
			.col-gutters;
			padding-top: 0;
		}

		@media @vp-sm-down {
			.col-gutters-sm;
		}

		> .summary {
			@media @vp-lg-up {
				.col-gutters;
				width: ~"calc(100% - 328px)";
				display: flex;
				flex-direction: column;
			}
			
			> .headline-text {
				margin-bottom: 1rem;
				margin-top: -0.25rem;
			}

			> .body-text {
				@media @vp-md-down {
					margin-bottom: 1rem;
				}
			}

			> .comparison-key {
				padding-top: 1rem;
				padding-bottom: 0.75rem;
				margin-top: auto;
			}
		}

		> .comparison-key {
			margin-left: auto;
			.col-gutters-responsive;
		}
			
		> .mean-results {
			width: 288px;
			display: flex;
			flex-wrap: wrap;
			flex-shrink: 0;
			
			&.-cols2 {
				width: 192px;

				@media @vp-sm-down {
					width: auto;
				}
			}
			
			@media @vp-md-down {
				margin-bottom: 1.5rem;
				width: auto;
			}
			
			@media @vp-sm-down {
				margin-top: 1.5rem;
				margin-bottom: 1rem;
			}
			
			@media @vp-lg-up {
				margin-right: (@grid-col-gutter - 10px);
				margin-left: auto;
			}

			@media print {
				break-inside: avoid;
			}
			
			> .body-text {
				text-align: center;
				width: 100%;
				.font-size(10);
				.spaced-uppercase(10);
				margin-bottom: 0;

				@media print {
					text-align: left;
				}

				@media @vp-sm-down {
					text-align: left;
				}

				@media @vp-lg-up {
					align-self: center;
				}
			}

			> .charts {
				display: flex;
				flex-wrap: wrap;
				margin: 0 auto;

				@media @vp-sm-down {
					margin-left: 0;
				}
				
				> .ring-chart {
					width: 96px;

					@media @vp-sm-down {
						margin-right: 0.5rem;

						&:last-child { margin-right: 0; }
					}
					
					@media print {
						margin-right: 0.5rem;
					}

					> .ringdata {
						> .rings,
						> .data {
							width: 80px;
							height: 80px;
							margin-left: auto;
							margin-right: auto;
						}

						> .data {
							height: auto;

							> .result {
								> .value {
									.font-size(24);
									.fw-regular;

									> .unit {
										font-size: 1rem;
										.fw-bold;
									}
								}
							}

							> .type {
								padding-top: 0;
							}
						}
					}
				}
				
				+ .charts {
					margin-top: 0.25rem;

					@media @vp-lg-down {
						width: 100%;
					}

					@media @vp-md {
						justify-content: center;
					}

					> .ring-chart {
						> .ringdata {
							> .rings,
							> .data {
								margin-left: auto;
								margin-right: auto;
							}
						}
					}	
				}
			}
		}

		> .icon-results {
			margin-top: auto;

			@media @vp-sm-down {
				margin-bottom: 1.5rem;
			}

			@media @vp-lg {
				.cols(7);
				margin-left: auto;
				.col-gutters;
				padding-right: percentage((0.5 / @grid-cols-total));
				margin-bottom: 2rem;
			}

			@media @vp-lg-up {
				display: flex;
				margin-top: 2rem;
			}

			@media @vp-md-down {
				margin-bottom: 1.5rem;
			}

			> .icons {
				margin-top: 1rem;
				
				@media @vp-sm-down {
					margin-top: 1.5rem;
				}

				@media @vp-lg-up {
					margin-top: 0;
					margin-left: @grid-col-gutter;
				}
				
				> .body-text {
					.fw-bold;
					padding-bottom: 0.25rem;
					border-bottom: 2px solid @yellow;

					@media @vp-sm-down {
						margin-bottom: 0.25rem;
					}

					@media @vp-lg-up {
						.font-size(10);
						.spaced-uppercase(10);
						margin-bottom: 0;
					}
				}

				+ .icons {
					@media @vp-sm-down {
						margin-top: 1rem;
					}

					> .body-text {
						border-bottom-color: @green;
					}
				}

				> .icon-grid {
					> .item {
						width: auto;
						margin-right: 1.5rem;
						
						&:last-child { margin-right: auto; }

						@media @vp-sm-down {
							margin-right: 1rem;
						}
					}
				}
			}
			
			&.-comparison {
				> .icons {
					> .body-text {
						border-bottom-color: @comparison-yellow;
					}

					+ .icons {
						> .body-text {
							border-bottom-color: @comparison-green;
						}
					}
				}
			}
		}

		> .data {
			width: 100%;

			@media @vp-xl {
				.cols(8);
			}
		}
	}

	> .results-block {
		margin-top: 2.5rem;
		margin-bottom: 2.5rem;
		padding-top: 2rem;
		padding-bottom: 2rem;

		@media @vp-lg-up {
			margin-left: -@grid-col-gutter;
			margin-right: -@grid-col-gutter;
			padding-left: @grid-col-gutter;
			padding-right: @grid-col-gutter;

			> .data {
				> .barchart-single {
					width: 100%;
					margin-left: 0;

					> .header {
						margin-right: 0;
						.col-gutters;
					}

					> .barchart {
						padding-left: 0;
						margin-right: 0;
					}
				}
			}
		}

		@media @vp-md-down {
			margin-top: 1rem;
			margin-bottom: 1rem;
			
			&:not(.report-overview) {
				box-shadow: none;
				// margin-left: 0;
				// margin-right: 0;
			}
		}
	}

	> .body-text {
		.col-gutters-responsive;
		margin-top: 1rem;

		> .summaryinput {
			> .form-field { padding-bottom: 0.5rem; }
			> .button-link {
				display: block;
				margin-left: auto;
			}
		}

		@media @vp-sm-down {
			&.-large {
				.font-size-body;
			}
		}
	}
}