// On desktop will be inside a 7 column container
.form-row {
	padding-bottom: 2rem;
	
	@media @vp-md-up {
		display: flex;
		
	}
	
	&.-nobottompadding {
		padding-bottom: 0;
	}
	
	@media @vp-sm-down {
		padding-bottom: 1rem;
	}

	> .label {
		.font-size-label-large;
		.col-gutters-responsive;

		@media @vp-sm-down {
			margin-bottom: 0.5em;
		}
		
		@media @vp-md-up {
			margin-bottom: 0;
			.cols(2, 7);
			
			&.-padtop {
				padding-top: unit((7 / 20), em);
			}
		}

	}
	
	> .fields {
		.col-gutters-responsive;
		
		@media @vp-md-up {
			.cols(5, 7);
		}

		> .content {
			.timing {
				.font-size-label;
				color: @brand-70;
				display: inline-block;
				width: 3.5rem;
			}
		}

		> .adjacent {
			@media @vp-md-up {
				.col-gutters-negate;
				align-items: center;
				display: flex;
			}

			> .label {
				.font-size-label;
				color: @brand-70;
				
				@media @vp-md-up {
					.cols(1, 5);
					padding-left: @grid-col-gutter;
				}

				@media @vp-sm-down {
					display: block;
					width: 100%;
					margin-bottom: 0.5em;
				}
			}

			> .form-input {
				&.-text {
					@media @vp-md-up {
						.cols(4, 5);
						.col-gutters;
					}

					@media @vp-sm-down {
						width: 100%;
					}
				}
			}

			+ .note {
				.font-size-body-small;
				margin-top: 0.5rem;
				margin-bottom: 0;
			}

			+ .adjacent { margin-top: 1rem; }
		}

		> .label {
			&.-subheading {
				.font-size-body;
				.fw-bold;
				margin-bottom: 1em;
			}
		}

		> * + .label.-subheading { margin-top: 2rem; }
	}

	> .form-input {
		flex-grow: 1;
	}

	> .matrix {
		.col-gutters-responsive;

		&.-incomplete {
			color: @red;
			position: relative;

			&:before {
				content: "";
				display: block;
				position: absolute;
				top: 7px;
				left: 0;
				width: 12px;
				height: 12px;
				border-radius: 6px;
				background: @red;

				@media @vp-sm-down { display: none; }
			}
		}

		> .question-title {
			margin-bottom: 2rem;

			+ .form-field.-adjacent {
				margin-top: -1rem;
			}
		}

		.option-inputs.-notapplicable {
			padding: 0;
		}

		> .scale {
			padding-left: 12px;
			padding-right: 12px;
			.col-gutters-negate-responsive;

			> .option-inputs {
				.col-gutters-responsive;
			}
		}

		> .form-field.-adjacent {
			margin-top: -1rem;

			.label {
				padding-left: 0;
				width: auto;
			}

			.form-input {
				padding-left: 0;
				padding-right: 0;
				flex-grow: 1;
			}
		}

		+ .matrix {
			margin-top: 4rem;
		}
	}

	&.-message {
		> .message {
			padding: unit((8 / 16), em) unit((8 / 16), em);
			border-radius: 5px;
			width: 100%;
			border: 1px solid;

			p {
				margin: 0;
				white-space: pre-wrap;
			}

			&.-error {
				background: mix(@red, white, 10%);
				color: @red;
				border-color: mix(@red, white, 35%);
			}

			&.-success {
				background: mix(@green, white, 10%);
				color: @green;
				border-color: mix(@green, white, 35%);
			}
		}
	}
}