.editable-section {
	border: 1px solid @border;
	border-radius: 5px;
	overflow: hidden;

	> .ui-heading {
		background: @background;
		margin: 0;
		padding: unit((4 / 12), em) 1rem unit((4 / 12), em) 1rem;
	}

	> .questions {
		padding: 0.75rem 1rem;

		> .list {
			padding-left: 0;
			margin-top: 0.25rem;
			margin-bottom: 1.25rem;

			&:last-child {
				margin-bottom: 0.25rem;
			}

			> li {
				list-style: none;
				margin: 0;

				+ li { margin-top: 0.75rem; }
			}
		}
	}

	+ .note {
		.font-size-body-small;
		margin-top: 0.5rem;
		margin-bottom: 0;
	}

	> .button-link {
		// margin-top: unit((12 / 12), em) unit((16 / 12), em);
	}

	+ .editable-section { margin-top: 2rem; }
}