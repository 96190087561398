.toast-container {
	width: 600px;
	padding: 8px;
	top: env(safe-area-inset-top, 7px);
	
	@media (max-width: 600px) {
		width: 100%;
		margin-left: 0;
		left: 0;
	}

	.toast-content {
		width: 100%;
		color: white;
		font-family: inherit;
		.font-size-body-small;
		.fw-medium;
		text-align: center;
		padding-left: 40px;
		padding-right: 40px;
		margin-bottom: 8px;
		border-radius: 500px;
		min-height: 50px;
		align-items: center;
		
		@media (max-width: 600px) {
			.font-size-body-small;
		}
		
		&:nth-child(1) { z-index: 10; }
		&:nth-child(2) { z-index: 9; }
		&:nth-child(3) { z-index: 8; }
		&:nth-child(4) { z-index: 7; }
		&:nth-child(5) { z-index: 6; }
		&:nth-child(6) { z-index: 5; }
		&:nth-child(7) { z-index: 4; }
		&:nth-child(8) { z-index: 3; }
		&:nth-child(9) { z-index: 2; }
		&:nth-child(10) { z-index: 1; }
		
		> .Toastify__toast-body {
			margin: 0 0 3px;
			/* Allow toast messages to render newlines */
			white-space: pre-line;

			> .Toastify__toast-icon {
				display: none;
			}
		}
		
		&.Toastify__toast--info {
			background: @green;
		}

		&.Toastify__toast--success {
			background: @green;
		}

		&.Toastify__toast--error {
			background: @red;
		}

		&.Toastify__toast--warning {
			background: @orange;
		}
	}
}

.Toastify__close-button {
	width: 24px;
	height: 24px;
	.hide-text;
	background-image: url("../img/message-close.svg");
	background-repeat: no-repeat;
	background-size: 100% 100%;
	background-position: 0 0;
    position: absolute;
    right: 13px;
    top: 50%;
	transform: translateY(-50%);

	svg {
		display: none;
	}
}
