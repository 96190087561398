.media-element, .media {
	overflow: hidden;

	> .image {
		> .aspect {
			width: 100%;
			height: 0;
			position: relative;
			background: @background;

			&.-ratio16x9 {
				padding-top: percentage((9 / 16));
			}

			&.-ratio4x3 {
				padding-top: percentage((3 / 4));
			}

			&.-square {
				padding-top: 100%;
			}

			&.-portrait {
				padding-top: percentage((4/3));
			}

			> img {
				display: block;
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				pointer-events: none;
				transition: opacity 1s;

				// &.lazyload {
				// 	opacity: 0;
				// }

				// &.lazyloaded {
				// 	opacity: 1;
				// }
			}
		}

		> img {
			display: block;
			margin-left: auto;
			margin-right: auto;
			max-width: 100%;
		}
	}

	.player-wrapper {
		position: relative;
		padding-top: 56.25% /* Player ratio: 100 / (1280 / 720) */
	}
	
	.react-player {
		position: absolute;
		top: 0;
		left: 0;
	}
}
