.keyboard-nav {
	.font-size-body-small;
	color: @body-light;
	margin-top: 2rem;

	@media @vp-sm-down { display: none; }
	
	> .option {
		margin: 0;
		text-align: center;
		.col-gutters-responsive;

		> .key {
			display: inline-block;
			border: 1px solid fade(@body-light, 80%);
			border-radius: 2px;
			padding-left: 2px;
			padding-right: 2px;
			line-height: 1;
		}
	}
}