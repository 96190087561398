._demoblock {
	margin-top: 3rem;
	margin-bottom: 3rem;
	.col-gutters-responsive;

	&:last-child { margin-bottom: 7rem; }

	> .form-input + .form-input { margin-top: 10px; }

	> .button-link { margin-right: 20px; margin-bottom: 20px; }
	> .status-slug { margin-right: 20px; margin-bottom: 20px; }

	&._no-gutter {
		.col-gutters-reset;
	}

	&._pagelinks {
		margin-top: 0;
		margin-bottom: 0;
	}

	&._negategutter {
		.col-gutters-reset;
	}

	&._demooptions {
		display: flex;
		flex-wrap: wrap;

		> ._demooptiongroup {
			margin-right: 4rem;
			margin-bottom: 2rem;
		}
	}
}

._demoiconlinks {
	margin-bottom: 2rem;

	.icon-text {
		display: inline-block;
		margin-right: 20px;
		margin-bottom: 20px;
	}
}

._demoicons {
	margin-bottom: 2rem;

	.icon-unit {
		margin-right: 20px;
		margin-bottom: 20px;
	}
}

@media @vp-md-up {
	._cols-10 { .cols(10); }
	._cols-8 { .cols(8); }
	._cols-7 { .cols(7); }
	._cols-6 { .cols(6); }
	._cols-5 { .cols(5); }
	._cols-2 { .cols(2); }
}

._nav {
	position: absolute;
	top: 0;
	right: 0;
	z-index: 1000;

	> .toggle {
		width: 40px;
		height: 74px;
		.hide-text;
		float: right;
		position: relative;
		margin: 0;

		@media @vp-sm-down {
			width: 48px;
			height: 48px;
		}

		&:after {
			content: "";
			display: block;
			position: absolute;
			width: 24px;
			height: 24px;
			background-size: 100% 100%;
			background-repeat: no-repeat;
			background-image: url("../img/interactive-menu.svg");
			left: 50%;
			top: 24px;
			transform: translate(-50%, -50%);
			opacity: 0.5;
		}
	}

	> .menu {
		float: right;
		clear: both;
		top: 74px;
		right: 0;
		background: white;
		border-radius: 7px;
		box-shadow: 0 1px 10px fade(@brand, 25%), 0 3px 20px fade(@brand, 15%);
		padding: 1rem;
		margin: 0.5rem;
		display: none;
		.font-size-body-small;

		> .actions {
			a {
				color: @brand;
				display: inline-block;
				background: @brand-secondary-light;
				border-radius: 4px;
				margin-bottom: 0.5rem;
				// padding: 0.25rem 0.5rem;
				border: 4px solid @brand-secondary-light;
				border-left-width: 6px;
				border-right-width: 6px;
		
				&:last-child { margin-bottom: 1.5rem; }
			}
		}

		> .ui-heading {
			margin-bottom: 0.25rem;
		}

		> .pages {
			padding-left: 0;
			margin: 0;

			a {
				&:hover { border-bottom: 1px solid @brand-70; }
			}
	
			> li {
				list-style: none;

				+ li { margin-top: 0.5rem; }
	
				> ul {
					padding-left: 0.5rem;
					margin-top: 0.25rem;
	
					> li {
						list-style: none;
						position: relative;
						padding-left: 1rem;

						&:before {
							content: "";
							display: block;
							position: absolute;
							left: 0;
							top: 8px;
							width: 0.5rem;
							height: 2px;
							background: @border;
						}
						
						+ li { margin-top: 0.25rem; }
					}
				}
			}
		}
	}

	&:hover {
		> .toggle {
			&:after {
				opacity: 1;
			}
		}
		> .menu { display: block; }
	}
}