.survey-overview {
	margin-bottom: 2rem;

	> .blocks {
		@media @vp-sm-down {
			display: flex;
			flex-direction: column;
		}

		@media @vp-md-up {
			display: flex;
			flex-wrap: wrap;
			margin-bottom: 3rem;
			background: white;
			padding: 1.5rem 0;
			box-shadow: 0 2px 25px fade(@brand-35, 50%), 0 1px 10px fade(@brand-35, 25%);
			border-radius: 7px;
			.col-gutters-as-margin-responsive;
		}
	
		> .block {
			>.body-text.reminder-link {
				margin:  0.5rem 0 0 0;
				a {
					.link-style-default;
				}
			}

			@media @vp-sm-down {
				// background: white;
				// padding: 1.5rem 0;
				// box-shadow: 0 2px 25px fade(@brand-35, 50%), 0 1px 10px fade(@brand-35, 25%);
				// border-radius: 7px;
				.col-gutters-sm;
				margin-bottom: 1rem;

				&.-participants,
				&.-completed {
					display: flex;
					flex-wrap: wrap;

					> .subheading-text {
						margin-right: 0.5rem;
						
						&:after {
							content: ":";
						}
					}

					> .value {
						.font-size-subheading;
						.fw-regular;
					}

					> .body-text {
						margin-left: auto;
						// width: 100%;
						align-self: flex-end;
					}
				}

				&.-participants {
					order: 1;
				}
				
				&.-completed {
					order: 2;
				}

				&.-scheduling {
					order: 4;
				}

				&.-actions {
					order: 5;

					> .subheading-text { display: none; }

					> .button-group {
						margin-top: 1rem;
					}
				}

				> .dates {
					> .date {
						margin-bottom: 0;

						+ .date { margin-top: 0.25rem; }
					}
					.ui-heading {
						display: inline-block;
						width: 70px;
					}
				}

				>.body-text.reminder-link {
					width: 100%;
					text-align: right;
					margin-bottom: 1rem;
				}
			}

			@media @vp-md-up {
				width: percentage((1 / 4));
				padding: 0.5rem 1rem;
				text-align: center;
				position: relative;
				display: flex;
				flex-direction: column;

				+ .block {
					&:before {
						content: "";
						position: absolute;
						left: 0;
						top: 0;
						height: 100%;
						width: 1px;
						background: @border;
					}
				}

				> .value {
					.font-size(80);
					letter-spacing: -(unit((2 / 80), em));
					line-height: 1;
					.fw-light;
					margin-top: 1rem;

					&.-unknown {
						.font-size(100);
						line-height: (80 / 100);
						font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
						font-weight: 200;
					}
				}

				> .body-text.-small {
					margin-top: 0.5rem;
					margin-bottom: 0;
					color: @brand-70;
				}

				> .dates,
				> .button-group {
					flex-grow: 1;
					display: flex;
					flex-direction: column;
					justify-content: space-around;

					> .date {
						> .ui-heading {
							display: block;
						}

						+ .date {
							// margin-top: 1rem;
						}
					}
				}

				> .button-group {
					justify-content: center;
					margin-left: @grid-col-gutter;
					margin-right: @grid-col-gutter;
				}
			}
		}
		
		> .progress {
			@media @vp-sm-down {
				.col-gutters-sm;
				order: 3;
				margin-top: -1rem; 
				margin-bottom: 1.5rem;

				> .context {
					display: flex;
					flex-wrap: wrap;
					align-items: flex-end;
	
					> .ui-heading {
						margin-bottom: 0;
						margin-top: 0.25rem;
						.font-size(10);
						.spaced-uppercase(10);
						line-height: (16 / 10);
					}

					> .chart-key {
						margin-left: auto;
						align-self: flex-end;
						margin-top: 0.25rem;
					}
				}
			}

			@media @vp-md-up {
				width: 100%;
				margin-top: 2rem;
				margin-left: 1.5rem;
				margin-right: 1.5rem;

				> .context {
					display: flex;
					justify-content: space-between;
					padding-top: 0.75rem;
	
					> .ui-heading {
						margin: 0;
						line-height: (18 / 14);
					}
				}
			}
		}
	}

	> .delete-download {
		display: flex;
		justify-content: space-between;

		.download-report {
			.col-gutters;
	
			>.subheading-text {
				margin-bottom: 0.75rem;
			}
		}
	
		> .delete-data {
			.col-gutters-as-margin;
			align-self: flex-start;
		}
	}
}