.barchart-single {
	position: relative;

	@media print {
		width: 100%;
	}

	@media @vp-lg-up {
		.cols(7, 8);
		margin-left: percentage((1 / 8));
	}

	> .header {
		@media @vp-sm-up {
			display: flex;
			flex-wrap: wrap;
		}
		
		@media @vp-lg-up {
			margin-right: percentage((0.5 / 7));
		}
		
		@media @vp-sm-down {
			flex-wrap: wrap;
		}
		
		> .chart-key {
			margin-bottom: 1.25rem;
			
			@media @vp-lg-up {
				.col-gutters;
			}
			
			> .values {
				justify-content: flex-start;
			}
		}
		
		> .options {
			margin-bottom: 1.25rem;

			@media @vp-sm-up {
				margin-top: -(unit((6 / @rem-base), rem));
				margin-left: auto;
			}

			@media @vp-xs {
				margin-top: 1rem;
			}

			> .icon-text {
				.font-size-body-small;
				opacity: 0.6;

				@media @vp-sm-up {
					margin-left: 1rem;
				}

				@media @vp-xs {
					margin-right: 1.5rem;
				}
			}
		}
	}
	
	> .barchart {
		@media @vp-lg-up {
			.col-gutters-responsive;
			margin-right: (@grid-col-gutter * 2);
			padding-right: 0;
		}

		@media print {
			width: 100%;

			> .chart {
				width: 100%;
			}
		}
	}
}