.error-message {
	max-width: (@grid-col-width * 4);
	margin-left: auto;
	margin-right: auto;

	> .body-text {
		.col-gutters-responsive;
	}
	
	> .button-group {
		.col-gutters-responsive;
		margin-top: 1.5rem;
	}
}