.comparison-key {
	> .key {
		display: flex;
		.font-size-body-small;
		.fw-bold;
		line-height: 1;
		align-items: center;
		margin: 0;

		+ .key { margin-top: 0.75rem; }

		> .indicator {
			width: 100px;
			height: 10px;
			overflow: hidden;
			border-radius: 5px;
			margin-right: 0.5rem;
			background: linear-gradient(to right,
				@red 5%,
				@yellow 45%,
				@yellow 55%,
				@green 95%
			);

			> svg {
				display: block;
				width: 100%;
				height: 100%;
				
				> rect {
					width: 100%;
					height: 100%;
					fill: url(#keyGradientDefault);
				}
			}

			&.-comparison {
				background: linear-gradient(to right,
					@comparison-red 5%,
					@comparison-yellow 45%,
					@comparison-yellow 55%,
					@comparison-green 94%
				);

				> svg {
					display: block;
					width: 100%;
					height: 100%;
					
					> rect {
						width: 100%;
						height: 100%;
						fill: url(#keyGradientComparison);
					}
				}
			}
		}
	}
}