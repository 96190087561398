.icon-unit {
	.hide-text;
	display: inline-block;
	vertical-align: middle;
	width: 24px;
	height: 24px;
	background-size: 100% 100%;
	background-repeat: no-repeat;
	opacity: 0.7;

	&.-flexi {
		width: 100%;
		height: 0;
		padding-top: 100%;
	}

	&.-small {
		width: 20px;
		height: 20px;
	}

	&.-large {
		width: 64px;
		height: 64px;
	}

	&.-activity { background-image: url("../img/icon/activity.svg"); }
	&.-actions { background-image: url("../img/icon/actions.svg"); }
	&.-add { background-image: url("../img/icon/add.svg"); }
	&.-alcohol { background-image: url("../img/icon/alcohol.svg"); }
	&.-alert { background-image: url("../img/icon/alert.svg"); }
	&.-archive { background-image: url("../img/icon/archive.svg"); }
	&.-article { background-image: url("../img/icon/article.svg"); }
	&.-audio { background-image: url("../img/icon/audio.svg"); }
	&.-back { background-image: url("../img/icon/back.svg"); }
	&.-balance { background-image: url("../img/icon/balance.svg"); }
	&.-bereavement { background-image: url("../img/icon/bereavment.svg"); }
	&.-business { background-image: url("../img/icon/business.svg"); }
	&.-caffeine { background-image: url("../img/icon/caffeine.svg"); }
	&.-children, &.-children_parenthood { background-image: url("../img/icon/children.svg"); }
	&.-close { background-image: url("../img/icon/close.svg"); }
	&.-compare { background-image: url("../img/icon/compare.svg"); }
	&.-confirm { background-image: url("../img/icon/confirm.svg"); }
	&.-commuting { background-image: url("../img/icon/commuting.svg"); }
	&.-crime, &.-victim_of_crime { background-image: url("../img/icon/crime.svg"); }
	&.-delete { background-image: url("../img/icon/delete.svg"); }
	&.-download { background-image: url("../img/icon/download.svg"); }
	&.-edit { background-image: url("../img/icon/edit.svg"); }
	&.-empty { background-image: url("../img/icon/empty.svg"); }
	&.-family, &.-family_problems { background-image: url("../img/icon/family.svg"); }
	&.-filter { background-image: url("../img/icon/filter.svg"); }
	&.-flag { background-image: url("../img/icon/flag.svg"); }
	&.-go { background-image: url("../img/icon/go.svg"); }
	&.-health { background-image: url("../img/icon/health.svg"); }
	&.-help, &.-other { background-image: url("../img/icon/help.svg"); }
	&.-house, &.-moving_house { background-image: url("../img/icon/house.svg"); }
	&.-illness, &.-ill_health { background-image: url("../img/icon/illness.svg"); }
	&.-job, &.-new_job { background-image: url("../img/icon/job.svg"); }
	&.-left { background-image: url("../img/icon/left.svg"); }
	&.-leisure { background-image: url("../img/icon/leisure.svg"); }
	&.-life { background-image: url("../img/icon/life.svg"); }
	// &.-link { background-image: url("../img/icon/link.svg"); }
	&.-money, &.-financial { background-image: url("../img/icon/money.svg"); }
	&.-more { background-image: url("../img/icon/more.svg"); }
	&.-nutrition { background-image: url("../img/icon/nutrition.svg"); }
	&.-order { background-image: url("../img/icon/order.svg"); }
	&.-person { background-image: url("../img/icon/person.svg"); }
	&.-relationship, &.-relationship_stress { background-image: url("../img/icon/relationship.svg"); }
	&.-relaxation { background-image: url("../img/icon/relaxation.svg"); }
	&.-remove { background-image: url("../img/icon/remove.svg"); }
	&.-report { background-image: url("../img/icon/report.svg"); }
	&.-resilience { background-image: url("../img/icon/resilience.svg"); }
	&.-right { background-image: url("../img/icon/right.svg"); }
	&.-search { background-image: url("../img/icon/search.svg"); }
	&.-sleep { background-image: url("../img/icon/sleep.svg"); }
	&.-time { background-image: url("../img/icon/time.svg"); }
	&.-tick { background-image: url("../img/icon/tick.svg"); }
	&.-unemployed, &.-redundancy_unemployment { background-image: url("../img/icon/unemployed.svg"); }
	&.-upload { background-image: url("../img/icon/upload.svg"); }
	&.-userhead { background-image: url("../img/icon/userhead.svg"); }
	&.-view { background-image: url("../img/icon/view.svg"); }
	&.-waiting { background-image: url("../img/icon/waiting.svg"); }
	&.-weight { background-image: url("../img/icon/weight.svg"); }
	&.-zap { background-image: url("../img/icon/zap.svg"); }

	&.-theme {
		opacity: 1;
		
		&.-health { background-image: url("../img/theme-health.png"); }
		&.-life { background-image: url("../img/theme-life.png"); }
		&.-worklife { background-image: url("../img/theme-worklife.png"); }
		&.-work { background-image: url("../img/theme-work.png"); }
		&.-resilience { background-image: url("../img/theme-resilience.png"); }
	}
}