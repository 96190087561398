.content-teaser {
	display: flex;
	
	> .media {
		width: 96px;
		flex-grow: 0;
		flex-shrink: 0;
		margin-right: @grid-col-gutter;

		> .aspect {
			width: 100%;
			height: 0;
			padding-top: percentage((3/4));
			position: relative;
			border-radius: 3px;
			overflow: hidden;

			&.-empty {
				background: @background;
			}

			> .image {
				display: block;
				width: 100%;
				height: 100%;
				position: absolute;
				top: 0;
				left: 0;
				border-radius: 3px;
			}

			> .icon-unit {
				width: 32px;
				height: 32px;
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
			}
		}
	}

	> .content {
		align-self: center;
		
		> .title {
			.font-size-body-large;
			.fw-bold;
			margin-bottom: 0.25rem;

			a {
				.link-style-default;
			}
		}
	}

	&.-small {
		> .media {
			width: 64px;
			margin-top: 0.25rem;

			> .aspect {
				padding-top: 100%;
			}
		}

		> .content {
			.font-size-body-small;

			> .title {
				.font-size-body;
				.fw-bold;
			}
		}
	}
}