.barchart-collection {
	position: relative;

	> .header {
		@media @vp-sm-up {
			display: flex;
			flex-wrap: wrap;
		}

		@media @vp-lg-up {
			.cols(5, 8);
			margin-left: auto;
		}

		> .chart-key {
			margin-bottom: 1.25rem;

			@media @vp-lg-up {
				.col-gutters;
			}
	
			> .values {
				justify-content: flex-start;
			}
		}

		> .options {
			margin-bottom: 1.25rem;
			
			@media @vp-sm-up {
				margin-top: -(unit((6 / @rem-base), rem));
				margin-left: auto;
			}

			@media @vp-xs {
				margin-top: 1rem;
			}
			
			> .icon-text {
				.font-size-body-small;
				opacity: 0.6;
				
				@media @vp-sm-up {
					margin-left: 1rem;
				}

				@media @vp-xs {
					margin-right: 1.5rem;
				}
			}
		}
	}
	
	> .barcharts {
		position: relative;
		padding-top: 0.5rem;

		> .intervals {
			position: absolute;
			z-index: 0;
			top: 0;
			bottom: 1.5rem;
			width: 100%;

			@media @vp-lg-up {
				.cols(5, 8);
				.col-gutters;
				right: 0;
			}

			> .area {
				height: 100%;
				display: flex;
				justify-content: space-between;
				margin-right: 0;
				
				@media @vp-lg-up {
					// .col-gutters-as-margin;
				}
	
				> .interval {
					width: 0px;
					border-left: 1px solid fade(@brand-light, 50%);
	
					&:first-child { opacity: 0; }
					&:last-child { opacity: 0; }
				}
			}
		}
	
		> .chart {
			position: relative;
			
			@media @vp-lg-up {
				display: flex;
			}

			@media print {
				page-break-inside: avoid;
			}
	
			> .label {
				line-height: 1rem;
				
				@media @vp-md-down {
					display: block;
					margin-bottom: 0.5rem;
					.fw-bold;
				}
				
				@media @vp-lg-up {
					margin-bottom: 0;
					text-align: right;
					.col-gutters;
					.cols(3, 8);
				}
			}
	
			> .bar-chart {
				@media @vp-lg-up {
					.col-gutters;
					.cols(5, 8);
				}

				&.-background {
					&:before {
						left: @grid-col-gutter;
					}
				}
			}

			&.-comparison {
				flex-wrap: wrap;

				> .bar-chart {
					+ .bar-chart {
						height: 6px;
						margin-top: 6px;
						// opacity: 0.6;

						@media @vp-lg-up {
							margin-left: percentage((3 / 8));
						}

						@media @vp-lg {
							margin-left: percentage((4 / @grid-cols-total));
						}

						&.-background {
							&:before {
								height: 6px;
							}
						}
						
						> .bar {
							&.-poor {
								background: @comparison-red;
								
								> svg { fill: @comparison-red; }
							}
							&.-good {
								background: @comparison-yellow;
								
								> svg { fill: @comparison-yellow; }
							}
							&.-great {
								background: @comparison-green;
								
								> svg { fill: @comparison-green; }
							}
						}
					}
				}

				+ .chart { margin-top: 1.5rem; }
			}
	
			&.-axis {
				.font-size-metadata;
				color: @brand-50;
	
				> .label {
					.font-size-metadata;

					@media @vp-md-down {
						position: absolute;
						top: 100%;
						left: 0;
						padding-left: @grid-col-gutter-sm;
					}
				}
	
				> .labels {
					display: flex;
					justify-content: space-between;
					
					@media @vp-lg-up {
						.col-gutters;
						.cols(5, 8);
					}
	
					> .label {
						width: 0px;
	
						> .value {
							display: inline-block;
							transform: translateX(-50%);
						}
					}
				}
			}
	
			+ .chart {
				margin-top: 2rem;

				@media @vp-sm-down {
					margin-top: 1.5rem;
				}
	
				&.-axis {
					margin-top: 1rem;
				}
			}
		}
	}
}