.privacy-notice {
	> .headline-text {
		.col-gutters-responsive;
		margin-bottom: 2rem;
	}

	> .subheading-text {
		.col-gutters-responsive;
		margin-bottom: 1rem;
	}

	> .body-text {
		.col-gutters-responsive;
		margin-bottom: 3rem;
	}
}