.recharts-wrapper {
	.recharts-text {
		.font-size-metadata;
		// color: @brand-50;
		fill: @brand-50;
	}

	.recharts-default-tooltip {
		background: white;
		box-shadow: 0 2px 25px fade(@brand-35, 50%), 0 1px 10px fade(@brand-35, 50%);
		border-radius: 7px;	
		border: none !important;
	}

	.recharts-tooltip-label {
		.font-size-body-small;
		.fw-bold;

		+ .recharts-tooltip-item-list { margin-top: 0.25rem !important; }
	}

	.recharts-tooltip-item-list {
		> .recharts-tooltip-item {
			.font-size-body-small;
			margin: 0;
			padding-top: 0 !important;
			padding-bottom: 0 !important;
			color: @body !important;

			+ .recharts-tooltip-item { margin-top: 0.25rem; }
		}
	}

	.recharts-tooltip-cursor {
		fill: @background;
	}
}