// On desktop will be inside a 7 column container
.form-field {
	padding-bottom: 2rem;

	&.-nobottompadding {
		padding-bottom: 0;
	}
	
	@media @vp-sm-down {
		padding-bottom: 1rem;
	}
	
	> .label {
		color: @brand;
	}

	&.-text,
	&.-table {
		.col-gutters-responsive;
	}

	+ .-text { margin-top: 1.5rem; }

	&.-hidden { display: none; }

	&.-stacked {
		> .label {
			.font-size-label;
			color: @brand-70;
			margin-bottom: 0.5em;
			display: block;

			&.-large {
				.font-size-label-large;
				color: @brand;
				text-transform: none;
				letter-spacing: 0;
				display: block;
				margin-bottom: 0.5em;
			}
		}

		> .form-input {
			width: 100%;
		}
	}

	&.-adjacent {
		@media @vp-md-up {
			display: flex;
		}

		> .label {
			.font-size-label-large;
			padding-top: unit((7 / 20), em);
			.col-gutters-responsive;

			>.note {
				.font-size-body-small;
				color: @body-light;
				.fw-regular;
			}
			
			@media @vp-md-up {
				.cols(2, 7);
				margin-bottom: 0;
			}

			@media @vp-sm-down {
				margin-bottom: 0.5rem;
				.font-size-body;
				.fw-bold;
			}
		}
		
		> .form-input,
		> .fields {
			.col-gutters-responsive;
			
			@media @vp-md-up {
				.cols(5, 7);
			}

			> .content {
				padding-top: unit((7 / 20), em);
			}

			> .content {
				.timing {
					.font-size-label;
					color: @brand-70;
				}
			}
		}
	}

	&.-disabled {
		> .label {
			color: @brand-35;
		}
	}

	&.-inline {
		display: flex;
		.col-gutters-responsive;

		> .form-input {
			&.-text,
			&.-select {
				flex-grow: 1;
			}
		}

		> * + * { margin-left: 0.5rem; }
	}

	&.-adduser {
		display: flex;
		flex-wrap: wrap;
		.col-gutters-responsive;
		
		> .form-input {
			margin-bottom: 0.5rem;

			&.name {
				width: 50%;
				padding-right: 0.25rem;

				+ .name {
					padding-left: 0.25rem;
				}
			}
			
			&.email {
				width: 50%;
				padding-right: 0.25rem;

				@media @vp-xs {
					width: 100%;
				}
			}

			&.permissions {
				padding-right: 0.25rem;
				flex-grow: 1;

				@media @vp-xs {
					padding-right: 0.5rem;
				}
			}
		}
	}

	&.-guttered {
		.col-gutters-responsive;
	}
}