.survey-link {
	@media @vp-md-up {
		display: flex;
		justify-content: space-between;

		> .buttoned-field {
			width: 360px;
			flex-shrink: 0;
			.col-gutters-responsive;
		}

		> .body-text {
			.col-gutters-responsive;
		}

		.body > & {
			.cols(8);
		}
	}

	.body > & {
		@media @vp-sm-down {
			.col-gutters-sm;

			> .body-text { margin-top: 0.5rem; }
		}
	}
}