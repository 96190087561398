.report-header {
	> .header {
		display: flex;
		flex-wrap: wrap;
		margin-bottom: 2rem;

		> .title-row {
			display: flex;
			justify-content: space-between;
			width: 100%;

			+ .details { margin-top: 0.5rem; }
		}
		
		> .strip {
			margin-bottom: 3rem;
			.col-gutters-responsive;

			display: flex;
			justify-content: space-between;
			width: 100%;

			p {
				margin-top: 0;

				a {
					display: inline-block;
					background-image: linear-gradient(
						to top, 
						fade(@body, 35%) 0px, 
						fade(@body, 35%) 1px, 
						fade(@body, 0%) 1.1px
					);

					&:hover {
						background-image: linear-gradient(
							to top, 
							fade(@body, 50%) 0px, 
							fade(@body, 50%) 1px, 
							fade(@body, 0%) 1.1px
						);
					}
				}
			}
		}

		> .subheading-text {
			width: 100%;
			.col-gutters-responsive;
			margin-bottom: 0.5em;

			&.-back {
				> a {
					position: relative;

					&:before {
						content: "";
						display: block;
						position: absolute;
						width: 24px;
						height: 24px;
						background-image: url("../img/icon/left.svg");
						background-size: 100% 100%;
						background-repeat: no-repeat;
						left: -24px;
						top: 50%;
						margin-top: -12px;
					}
				}

				@media @vp-sm-down {
					.font-size(20);
					line-height: (24 / 20);

					> a {
						padding-left: 18px;
	
						&:before {
							left: -6px;
						}
					}
				}
			}
		}

		> .headline-text,
		> .title-row > .title-wrapper {
			.col-gutters-responsive;

			+ .details { margin-top: 0.5rem; }
		}

		> .name,
		> .options,
		> .title-row > .options {
			align-self: flex-end;
			margin-bottom: unit((10 / 18), em);
			.col-gutters-responsive;
			margin-left: auto;

			@media @vp-sm-down {
				margin-left: 0;
				width: 100%;
			}
		}

		> .name {
			a {
				display: inline-block;
				background-image: linear-gradient(
					to top, 
					fade(@body, 35%) 0px, 
					fade(@body, 35%) 1px, 
					fade(@body, 0%) 1.1px
				);

				&:hover {
					background-image: linear-gradient(
						to top, 
						fade(@body, 50%) 0px, 
						fade(@body, 50%) 1px, 
						fade(@body, 0%) 1.1px
					);
				}
			}
		}

		> .title-row > .options {
			display: flex;
		}

		> .options,
		> .title-row > .options {
			@media @vp-sm-down {
				margin-top: 1rem;
			}
			
			> .icon-text {
				+ .icon-text {
					margin-left: 2rem;
				}
			}
		}

		> .chart-key {
			align-self: flex-end;
			margin-bottom: unit((10 / 18), em);
			.col-gutters-responsive;
			margin-left: auto;
		}

		> .details {
			// margin-top: 0;
			// margin-bottom: 3rem;
			.font-size-body-hero;
			width: 100%;
			

			@media @vp-md-up {
				display: flex;
				flex-wrap: wrap;
				justify-content: space-between;
				align-items: center;
			}

			> .detail {
				display: inline-block;
				.col-gutters-responsive;

				> .icon-text {
					.font-size-body-hero;
					.fw-bold;
					padding-left: 32px;

					&.report-print {
						.fw-regular;
						.font-size-body-large;
						&:hover {
							cursor: pointer;
						}
					}

					&:before {
						width: 28px;
						height: 28px;
						margin-top: -14px;
					}
				}
			}
		}
	}

	> .resources {
		margin-top: 3rem;

		> .text {
			.col-gutters-responsive;
		}

		> .link-lists {
			margin-top: 3rem;

			@media @vp-sm-down {
				.col-gutters-sm;
			}
		}
	}

	> .resources-page {
		margin-top: 3rem;
	}

	&.-centered {
		text-align: center;

		> .header {
			display: block;
		}
	}
}