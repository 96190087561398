.ring-chart {
	position: relative;

	> .ringdata {
		position: relative;

		> .rings {
			width: 100%;
			height: 0;
			padding-top: 100%;
			position: relative;
			
			> .svg {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;

				circle {
					fill: none;
				}

				> .ring {
					transform-origin: 50% 50%;

					> .background {
						stroke: @background;
					}
		
					> .value {
						animation: RingProgress 1s ease-in-out forwards;
						stroke-linecap: round;
						stroke: red;
					}
				}
			}
		}

		&.-poor > .rings > .svg > .ring > .value { stroke: @red; }
		&.-good > .rings > .svg > .ring > .value { stroke: @yellow; }
		&.-great > .rings > .svg > .ring > .value { stroke: @green; }
	
		> .data {
			text-align: center;
			margin: 0;

			> .result {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 0;
				padding-top: 100%;
				display: block;

				> .value {
					position: absolute;
					top: 50%;
					left: 0;
					width: 100%;
					text-align: center;
					.font-size(32);
					line-height: 1;
					.fw-light;
					transform: translateY(-50%);

					> .unit {
						.font-size(20);
						.fw-regular;
					}
				}
			}

			> .type {
				display: block;
				.font-size(10);
				.spaced-uppercase(10);
				.fw-bold;
				color: @brand-70;
				line-height: (12 / 10);
				width: 100%;
				padding-top: 0.5rem;
			}
		}
	}

	&.-comparison {
		> .ringdata {
			&.-poor > .rings > .svg > .ring > .value { stroke: @comparison-red; }
			&.-good > .rings > .svg > .ring > .value { stroke: @comparison-yellow; }
			&.-great > .rings > .svg > .ring > .value { stroke: @comparison-green; }
		}			
	}

	&.-small {
		> .ringdata {
			> .rings {
				width: 64px;
				height: 64px;
				padding-top: 0;
			}

			> .data {
				width: 64px;
				height: 0;
				position: relative;

				> .result {
					width: 64px;
					height: 64px;
					padding-top: 0;
					top: auto;
					bottom: 0;

					> .value {
						.font-size(18);
						.fw-regular;
						
						> .unit {
							.font-size(12);
							margin-left: 1px;
						}
					}
				}

				> .type { display: none; }
			}
		}
	}
}

@keyframes RingProgress {
	0% {
		stroke-dasharray: 0 100;
	}
}