.progress-bar {
	height: 12px;
	background: darken(@brand, 10%);
	padding: 2px;

	> .track {
		position: relative;

		> .progress {
			background: @brand-secondary;
			height: 8px;
			min-width: 8px;
			border-radius: 4px; 
		}
	}
}