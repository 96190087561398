.resources-page {
	@media @vp-md-up {
		display: flex;
	}

	> .content {
		@media @vp-md-up {
			.cols(5, 10);
			position: relative;

			&:after {
				content: "";
				display: block;
				width: 1px;
				height: 100%;
				background: @border;
				position: absolute;
				top: 0;
				right: @grid-col-gutter;
			}
		}

		@media @vp-sm-down {
			margin-bottom: 2rem;
		}

		> .summary {
			@media @vp-md-up {
				.col-gutters;
				margin-bottom: 3rem;
				.cols(4.5, 5);
			}

			@media @vp-sm-down {
				margin-bottom: 2rem;
			}

			> .subheading-text {
				margin-bottom: 1rem;
			}
		}

		> .charts {
			@media @vp-md-up {
				.cols(4.5, 5);
				.col-gutters;

				> .chart-stack {
					padding-bottom: 1.5rem;
					
					> .body-text {
						.fw-bold;
						margin-bottom: 0.75rem;
					}
	
					> .chart {
						width: 100%;
					}
				}
	
				> .chart-key {
					margin-top: 0.5rem;
	
					> .values {
						justify-content: flex-start;
					}
				}
			}

			> .icons {
				+ .icons {
					margin-top: 2rem;

					@media @vp-sm-down {
						margin-top: 1.5rem;
					}
				}
			}

			@media @vp-sm-down {
				> .chart-stack {
					padding-bottom: 1rem;
				}
			}
		}
	}

	@media @vp-md-up {
		> .resource-summaries {
			.cols(5, 10);
			.col-gutters;
			margin-left: auto;
			
			&.-single {
				margin-right: auto;
				.cols(7, 10);
			}
		}

		&.-evencols {
			> .content {
				> .summary {
					width: auto;
					padding-right: (@grid-col-gutter * 2);

					> .articles {
						margin-top: 2rem;
			
						> .content-teaser {
							+ .content-teaser {
								margin-top: 1.5rem;
							}
						}
					}
				}

				&:after { right: 0; }
			}
			> .resource-summaries {
				padding-left: 0;
				padding-right: 0;

				> .summary {
					.col-gutters-responsive;
					margin-left: auto;
					padding-left: (@grid-col-gutter * 2);
					// margin-left: percentage((0.5 / 5));
				}
			}
		}
	}

	@media @vp-sm-down {
		> .content {
			.col-gutters-sm;
		}

		> .resource-summaries {
			.col-gutters-sm;
		}
	}
}