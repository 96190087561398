.icon-svg {
	.hide-text;
	display: inline-block;
	vertical-align: middle;
	width: 24px;
	height: 24px;
	background-size: 100% 100%;
	background-repeat: no-repeat;
	opacity: 0.7;

	&.-flexi {
		width: 100%;
		height: 0;
		padding-top: 100%;
	}

	&.-small {
		width: 20px;
		height: 20px;
	}

	> svg {
		display: block;
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
	}
}