.button-group {
	display: flex;

	> .icon-text {
		margin-top: unit((8 / @rem-base), em);
		margin-bottom: unit((8 / @rem-base), em);
	}

	&.-split {
		justify-content: space-between;
	}
	
	&.-left {
		> .button-link,
		> .icon-text {
			margin-right: 1rem;
			
			&:last-child { margin-right: 0; }
		}
	}

	&.-right {
		> .button-link,
		> .icon-text {
			+ .button-link,
			+ .icon-text {
				margin-left: 1rem;
			}
		}
	}

	&.-centered {
		justify-content: center;
		width: 100%;

		@media @vp-sm-down {
			flex-direction: column-reverse;
		}

		> .button-link,
		> .icon-text {
			+ .button-link,
			+ .icon-text {
				margin-left: 1rem;

				@media @vp-sm-down {
					margin-left: 0;
					margin-bottom: 1rem;
				}
			}
		}
	}

	&.-stacked {
		display: block;

		> .button-link {
			display: block;
			width: 100%;
			margin-top: 0.5rem;

			&.-destructive,
			&.-action {
				margin-top: 1.5rem;
			}
		}
	}
}