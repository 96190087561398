.link-lists {
	display: flex;
	flex-wrap: wrap;
	
	@media @vp-lg-up {
		justify-content: space-between;
	}
	
	> .list {
		@media @vp-lg-up {
			.cols(3, 10);
			.col-gutters-responsive;
		}

		@media @vp-sm-down {
			+ .list { margin-top: 2rem; }
		}

		> .subheading {
			display: block;
			border-bottom: 4px solid @brand-secondary;
			padding-bottom: 0.5rem;
		}

		> .linklist {
			padding-left: 0;
			list-style: none;
			margin-top: 1rem;
			margin-bottom: 0;

			strong { .fw-bold; }

			li {
				+ li { margin-top: 1rem; }

				.text {
					.link-style-default;
				}
			}

			.icon-text {
				white-space: initial;
				padding-left: 30px;

				&:before {
					top: 0;
					margin-top: 0;
				}
			}
		}
	}
}