.report-tile {
	.col-gutters-responsive;
	
	> .container {
		display: block;
		.shadowed-block;
		display: flex;
		flex-direction: column;
		padding: 1.5rem @grid-col-gutter 0;
		height: 100%;

		> .chart {
			margin-bottom: 1.5rem;
		}
		
		> .content {
			margin-bottom: 1.5rem;

			> .subheading-text {
				margin-bottom: 1rem;
			}

			> p {
				margin: 0;
			}
		}

		> .button-group {
			margin-top: auto;
			padding: 1rem @grid-col-gutter;
			margin-left: -(@grid-col-gutter);
			margin-right: -(@grid-col-gutter);
			background: @background;

			&.-right {
				.button-link {
					margin-left: auto;
				}
			}
			> .button-link.-text { padding-right: 0; padding-left: 0; }
		}
	}
}