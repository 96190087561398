.site-grid {
	display: flex;
	z-index: 9999;
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	margin-left: auto;
	margin-right: auto;
	width: 100%; // IE10
	pointer-events: none;
	opacity: 0.05;

	> .width-limit {
		display: flex;
		width: 100%;
	}
	
	.column {
		flex: 1;
		background-color: #f00;
		margin-left: @grid-col-gutter;
		margin-right: @grid-col-gutter;
	}
}

// FOR TESTING SOME INITIAL MARKUP ONLY
.grid-test {
	max-width: @constraint-site;
	margin: 50px 0;

	.grid-row {
		height: 10px;
		display: flex;
		margin-bottom: 5px;

		> div {
			flex: 1;
			background: fade(#f00, 10);
			.col-gutters;
			height: 100%;

			&:after {
				content: "";
				display: block;
				width: 100%;
				height: 100%;
				background: fade(#0f0, 20);
			}
		}

		&.-cols1	{ max-width: percentage((1 / @grid-cols-total)); }
		&.-cols2	{ max-width: percentage((2 / @grid-cols-total)); }
		&.-cols3	{ max-width: percentage((3 / @grid-cols-total)); }
		&.-cols4	{ max-width: percentage((4 / @grid-cols-total)); }
		&.-cols5	{ max-width: percentage((5 / @grid-cols-total)); }
		&.-cols6	{ max-width: percentage((6 / @grid-cols-total)); }
		&.-cols7	{ max-width: percentage((7 / @grid-cols-total)); }
		&.-cols8	{ max-width: percentage((8 / @grid-cols-total)); }
		&.-cols9	{ max-width: percentage((9 / @grid-cols-total)); }
		&.-cols10	{ max-width: percentage((10 / @grid-cols-total)); }
		&.-cols11	{ max-width: percentage((11 / @grid-cols-total)); }
		&.-cols12	{ max-width: percentage((12 / @grid-cols-total)); }
		&.-cols13	{ max-width: percentage((13 / @grid-cols-total)); }
		&.-cols14	{ max-width: percentage((14 / @grid-cols-total)); }
		&.-cols15	{ max-width: percentage((15 / @grid-cols-total)); }
		&.-cols16	{ max-width: percentage((16 / @grid-cols-total)); }
		&.-cols17	{ max-width: percentage((17 / @grid-cols-total)); }
		&.-cols18	{ max-width: percentage((18 / @grid-cols-total)); }
		&.-cols19	{ max-width: percentage((19 / @grid-cols-total)); }
		&.-cols20	{ max-width: percentage((20 / @grid-cols-total)); }
		&.-cols21	{ max-width: percentage((21 / @grid-cols-total)); }
		&.-cols22	{ max-width: percentage((22 / @grid-cols-total)); }
		&.-cols23	{ max-width: percentage((23 / @grid-cols-total)); }
		&.-cols24	{ max-width: percentage((24 / @grid-cols-total)); }
	}
}
