._no-br { white-space: nowrap; }

._hide-text {
	.hide-text();
}

._sr-only {
	.sr-only();
}

._sr-only-focusable {
	.sr-only-focusable();
}

._sm-down {
	@media @vp-md-up { display: none; }
}

._md-up {
	@media @vp-sm-down { display: none; }
}

._no-print {
	@media print { display: none !important; }
}