.side-menu {
	> .menu {
		padding: 0;
		margin: 0;
		list-style: none;
		border-top: 1px solid @border;

		> .menuitem {
			border-bottom: 1px solid @border;

			> .link {
				display: block;
				.fw-bold;
				padding: 0.5rem 0;

				&:before {
					content: "";
					display: inline-block;
					vertical-align: middle;
					width: 20px;
					height: 20px;
					background-size: 100% 100%;
					background-repeat: no-repeat;
					background-image: url("../img/icon/right.svg");
					opacity: 0.7;
					position: relative;
					top: -2px;
					margin-right: 4px;
				}

				&.-active {
					&:before {
						transform: rotate(90deg);
					}
				}
			}

			> ul {
				padding: 0;
				margin: 0 0 1rem 24px;
				list-style: none;

				> .menuitem {
					+ .menuitem {
						margin-top: 0.25rem;
					}

					> .link {
						&.-active {
							font-style: italic;
							position: relative;

							&:before {
								content: "";
								display: block;
								background: @brand-secondary;
								position: absolute;
								bottom: -4px;
								left: -2px;
								right: -2px;
								height: 2px;
							}
						}
					}
				}
			}
		}
	}
}