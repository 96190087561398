.site-body {
	padding-top: 5rem;
	padding-bottom: 6rem;

	@media @vp-md {
		padding-top: 3rem;
		padding-bottom: 5rem;
	}
	
	@media @vp-sm-down {
		padding-top: 2rem;
		padding-bottom: 4rem;
	}
}