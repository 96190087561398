.arc-chart {
	position: relative;
	height: 0;
	padding-top: 50%;

	> .arc {
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		overflow: hidden;

		&:before,
		&:after {
			content: "";
			display: block;
			position: absolute;
			border-radius: 100%;
		}

		&:before {
			width: 100%;
			height: 200%;
			background-image: url("../img/arc-gradient.png");
			background-size: 100% 50%;
			background-position: 0 0;
			background-repeat: no-repeat;
			transform: scale(0.98);
		}

		&:after {
			background: white;
			width: 50%;
			height: 100%;
			top: 50%;
			left: 25%;
		}
	}

	> .indicator {
		width: 0;
		height: 100%;
		position: absolute;
		left: 50%;
		top: 0;
		transform-origin: 50% 100%;

		&:before {
			content: "";
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			width: 4px;
			height: 100%;
			background: @brand;
			border-radius: 2px;
			margin-left: -2px;
		}

		&:after {
			content: "";
			display: block;
			width: 16px;
			height: 16px;
			border-radius: 8px;
			background: @brand;
			position: absolute;
			top: 100%;
			left: 0;
			margin-left: -8px;
			margin-top: -8px;
		}

		&.-compare {
			&:before {
				width: 8px;
				height: 52%;
				margin-left: -4px;
				border-radius: 8px;
				opacity: 0.25;
			} 
		}
	}

	&.-small {
		> .indicator {
			&:before {
				width: 2px;
				border-radius: 1px;
				margin-left: -1px;
			}
	
			&:after {
				width: 10px;
				height: 10px;
				margin-left: -5px;
				margin-top: -5px;
			}
		}
	}
}