.data-table {
	width: 100%;
	border-bottom: 4px solid @background;

	> thead {
		th {
			background: @background;
			.font-size(10);
			.spaced-uppercase(10);
			line-height: (12 / 10);
			.fw-black;
			padding-top: unit((10 / 10), em);
			padding-bottom: unit((10 / 10), em);

			&:first-child { border-top-left-radius: 5px; }
			&:last-child { border-top-right-radius: 5px; }

			.toggle {
				border-bottom: 1px solid @brand-35;

				&.-small {
					.font-size(10);
					text-transform: none;
					letter-spacing: 0;
					font-weight: normal;
				}

				&:hover {
					border-bottom-color: @brand-70;
				}
			}

			.sort {
				border-bottom: 1px dotted @brand-35;
				
				&:hover {
					border-bottom-color: @brand-70;
				}
			}
		}
	}

	th, td {
		padding-left: 0.5rem;
		padding-right: 0.5rem;

		&.rightalign { text-align: right; }
		&.centeralign { text-align: center; }

		button { cursor: pointer; }

		.inline-option {
			display: block;
			line-height: 24px;
		}
	}

	> tbody {
		tr {
			@media @vp-md-up {
				&:hover:not(.more):not(.empty) {
					td, th {
						background-color: @brand-secondary-light;
					}
				}
			}

			&:first-child {
				td, th {
					padding-top: unit((14 / @rem-base), rem);
				}
			}

			&:last-child {
				td, th {
					@media @vp-md-up {
						padding-bottom: unit((14 / @rem-base), rem);
					}
				}
			}

			&.more {
				td, th {
					padding-top: unit((14 / @rem-base), rem);
				}
			}
			
			&.empty {
				td {
					padding-top: 3rem;
					padding-bottom: 3rem;
					text-align: center;
				}
			}
		}

		td, th {
			padding-top: unit((10 / @rem-base), rem);
			padding-bottom: unit((10 / @rem-base), rem);
		}

		th {
			.fw-bold;

			> a.title {
				.link-style-default;
			}
		}
	}

	.icon-unit {
		opacity: 0.7;

		&.-empty,
		&.-waiting { opacity: 1; }

		&.-empty {
			@media @vp-sm-down { display: none; }
		}

		&:hover { opacity: 1; }

		+ .icon-unit { margin-left: 0.5rem; }

		&.-more { cursor: pointer; }
	}

	&.-small {
		.font-size(@fs-body-small);

		> tbody {
			tr {
				&:first-child {
					td, th { padding-top: unit((10 / @rem-base), rem); }
				}
	
				&:last-child {
					@media @vp-md-up {
						td, th { padding-bottom: unit((10 / @rem-base), rem); }
					}
				}
			}
	
			td, th {
				padding-top: unit((8 / @rem-base), rem);
				padding-bottom: unit((8 / @rem-base), rem);
			}
		}
	}
}