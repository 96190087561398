// On desktop this sits inside a 5 column container
.schedule-date {
	display: flex;
	
	@media @vp-md-up {
		.col-gutters-negate;
		align-items: center;
	}

	@media @vp-sm-down {
		flex-wrap: wrap;
	}

	> .label {
		.font-size-label;
		color: @brand-70;
		
		@media @vp-md-up {
			.cols(1, 5);
			padding-left: @grid-col-gutter;
		}

		@media @vp-sm-down {
			width: 100%;
			margin-bottom: 0.5em;
		}
	}

	> .form-input {
		&.-date {
			@media @vp-md-up {
				.cols(2.5, 5);
				.col-gutters;
			}

			@media @vp-sm-down {
				.cols(8);
			}
		}

		&.-time {
			@media @vp-md-up {
				margin-left: (-(@grid-col-gutter / 2));
			}

			@media @vp-sm-down {
				.cols(4);
				padding-left: @grid-col-gutter-sm;
				// margin-left: 0;

				> .field { max-width: 100%; }
			}
		}
	}

	> .note {
		.font-size-body-small;
		color: @body-light;
		margin: 0;
		
		@media @vp-md-up {
			.col-gutters;
		}

		@media @vp-sm-down {
			width: 100%;
			margin-top: 0.5em;
		}
	}

	+ .schedule-date { margin-top: 1rem; }
}