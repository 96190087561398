.footer-actions {
	position: relative;
	margin-top: 2rem;
	padding-top: unit(((@rem-base + 4) / @rem-base), rem);
	padding-bottom: 1rem;
	display: flex;

	@media @vp-sm-down {
		flex-wrap: wrap;
	}

	&:before {
		.positioned-border-responsive;
		top: 0;
	}

	&.-borderless {
		padding-top: 1rem;
		&:before { display: none; }
	}

	> .button-group {
		.col-gutters-responsive;

		&.-right { margin-left: auto; }
		// &.-left { margin-right: auto; }

		.button-link {
			@media @vp-xs {
				&.-text {
					.font-size-body-small;
					padding-top: 0.6rem;
					padding-left: 1em;
					padding-right: 1em;
				}

				&.-action {
					&.-next {
						background-size: 24px 18px;
						padding-right: 2em;
						padding-left: 1em;
					}
				}
				
				&.-secondary {
					&.-back {
						background-size: 24px 18px;
						padding-right: 1em;
						padding-left: 2em;
					}
				}
			}
		}
	}

	> .pagination-block {
		.col-gutters-responsive;
		margin-top: 0;

		@media @vp-sm-down {
			width: 100%;
		}
		
		> .pages {
			margin-left: 1.5rem;
			margin-right: 1.5rem;
		}
	}
}

.form-field,
.form-row {
	+ .footer-actions {
		margin-top: 1rem;
	}
}