.no-participants {
	text-align: center;
	min-height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	.col-gutters-responsive;
	position: relative;
	padding-top: 1rem;
	padding-bottom: 2rem;

	&:before {
		.positioned-border-responsive;
		top: 0;
	}

	> .subheading-text {
		margin-bottom: 1.5rem;

		&:before {
			content: "";
			display: block;
			width: 48px;
			height: 48px;
			background-image: url("../img/participants.svg");
			background-size: 100% 100%;
			background-repeat: no-repeat;
			margin-left: auto;
			margin-right: auto;
			margin-bottom: 1rem;
		}
	}

	> .body-text {
		.cols(3, 6);
		margin-left: auto;
		margin-right: auto;
	}

	+ .footer-actions { margin-top: 0; }
}