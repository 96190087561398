.report-overview {
	display: flex;
	flex-wrap: wrap;
	
	@media print {
		display: flex;
		flex-wrap: wrap;
		flex-direction: row;
		border: 1px solid @border;
		border-radius: @border-radius-large;
	}

	> .detail {
		text-align: center;
		padding-top: 2rem;
		padding-bottom: 2rem;
		.col-gutters-responsive;

		> .subheading-text {
			@media @vp-sm-down {
				.font-size-body-large;
				.fw-bold;
			}
		}

		> .body-text.-small {
			margin-top: 1.5rem;
		}

		> .value {
			.font-size(80);
			letter-spacing: -(unit((2 / 80), em));
			line-height: 1;
			.fw-light;
			margin-top: auto;
			margin-bottom: auto;

			@media @vp-md {
				.font-size(64);
				.fw-regular;
			}

			@media print {
				.font-size(64);
				.fw-regular;
			}

			@media screen and @vp-sm-down {
				margin-bottom: 0;
				margin-top: 1.25rem;
				.font-size(48);
				.fw-regular;
			}

			&.-small {
				.font-size(32);
				line-height: (40 / 32);
				letter-spacing: 0;
				.fw-regular;
				margin-top: auto;
				margin-bottom: auto;

				@media @vp-md {
					.font-size(24);
					.fw-bold;
				}
				
				@media print {
					.font-size(24);
					.fw-bold;
				}

				@media screen and  @vp-sm-down {
					margin-bottom: 0;
					margin-top: 1rem;
					.font-size(22);
					.fw-bold;
				}
			}
		}

		> .chart > .chart-key {
			margin-top: 1.5rem;
		}

		@media screen and @vp-sm-down {
			padding-left: @grid-col-gutter;
			padding-right: @grid-col-gutter;
			width: 50%;

			&:first-child {
				width: 100%;
				border-bottom: 1px solid @border;
				padding-left: (@grid-col-gutter * 2);
				padding-right: (@grid-col-gutter * 2);
			}

			&:nth-child(3) {
				box-shadow: inset 1px 0 0 @border;
			}
		}
		
		@media @vp-md-up {
			.cols(5);
			display: flex;
			flex-direction: column;
			padding-bottom: 2.5rem;

			> .subheading-text {
				margin-bottom: 1.5rem;
			}
			
			+ .detail {
				border-left: 1px solid @border;
				.cols(3.5);
			}
		}

		@media @vp-md {
			&:first-child {
				padding-left: (@grid-col-gutter * 2);
				padding-right: (@grid-col-gutter * 2);
			}
		}

		@media print {
			.cols(5);
			display: flex;
			flex-direction: column;
			padding-bottom: 2.5rem;

			&:first-child {
				padding-left: (@grid-col-gutter * 2);
				padding-right: (@grid-col-gutter * 2);
			}

			> .subheading-text {
				margin-bottom: 1.5rem;
			}
			
			+ .detail {
				border-left: 1px solid @border;
				.cols(3.5);
			}
		}

		> .chart {
			@media @vp-lg-up {
				margin-left: (@grid-col-gutter * 2);
				margin-right: (@grid-col-gutter * 2);
			}

			@media screen and @vp-sm-down {
				margin-top: 1rem;
			}
			
			@media screen and @vp-md-down {
				width: 100%;
				max-width: 350px;
				margin-left: auto;
				margin-right: auto;
			}

			@media print {
				width: 100%;
			}
		}
	}
}