.scale-key {
	.font-size-body-small;
	color: @brand-70;
	display: flex;
	justify-content: space-between;
	margin-top: 1rem;

	&.-disabled {
		color: @brand-35;
	}

	> .key {
		width: percentage((1 / 3));
		text-align: center;
		padding: 0 5px;
		line-height: 1.2;
		// outline: 1px solid green;

		.-incomplete & {
			color: @red;
		}

		@media @vp-sm-down {
			.font-size(12);
			
			&:first-child {
				text-align: left;
				padding-left: 0;
			}
			&:last-child {
				text-align: right;
				padding-right: 0;
			}
		}
	}

	&.labels-2,
	&.labels-3 {
		.col-gutters-as-margin-responsive;

		> .key {
			&:first-child {
				margin-left: -12px;
				text-align: left;
				padding-left: 0;
			}
	
			&:last-child {
				margin-right: -12px;
				text-align: right;
				padding-right: 0;
			}
		}
	}

	&.labels-2 {
		> .key {
			width: 50%;
		}
	}

	&.labels-3 {
		> .key {
			width: percentage((1 / 3));
		}
	}
	
	&.labels-4 { .scale-label-spacing(4); }
	&.labels-5 { .scale-label-spacing(5); }
	&.labels-6 { .scale-label-spacing(6); }
	&.labels-7 {
		.scale-label-spacing(7);

		> .key {
			@media @vp-sm-down {
				&:nth-child(2),
				&:nth-child(3),
				&:nth-child(5),
				&:nth-child(6) { display: none; }
			}
		}
	}
	&.labels-8 {
		.scale-label-spacing(8);

		> .key {
			@media @vp-sm-down {
				&:nth-child(2),
				// &:nth-child(3),
				&:nth-child(4),
				&:nth-child(5),
				// &:nth-child(6),
				&:nth-child(7) { display: none; }
			}
		}
	}
	&.labels-9 {
		.scale-label-spacing(9);

		> .key {
			@media @vp-sm-down {
				&:nth-child(2),
				&:nth-child(3),
				&:nth-child(4),
				&:nth-child(6),
				&:nth-child(7),
				&:nth-child(8) { display: none; }
			}
		}
	}
	&.labels-10 {
		.scale-label-spacing(10);

		> .key {
			@media @vp-sm-down {
				&:nth-child(2),
				&:nth-child(3),
				// &:nth-child(4),
				&:nth-child(5),
				&:nth-child(6),
				// &:nth-child(7),
				&:nth-child(8),
				&:nth-child(9) { display: none; }
			}
		}
	}
}