.question-table {
	.col-gutters-responsive;

	@media @vp-sm-down {
		.font-size-body-small;
	}

	+ .question-table { margin-top: 3rem; }

	.data-table {
		tbody {
			td, th {
				vertical-align: top;

				&.options {
					white-space: nowrap;
					
					>.-order {
						cursor: move;
					}
				}
			}

			tr {
				th {
					@media @vp-sm-down {
						padding-left: 0;
					}
				}

				td {
					&:last-child {
						@media @vp-sm-down {
							padding-right: 0;
						}	
					}
				}
			}
		}
	}

	> .subheading-text {
		margin-bottom: 1.5rem;
	}

	.question {
		white-space: nowrap;
		cursor: pointer;

		&:before {
			content: "";
			display: inline-block;
			vertical-align: middle;
			width: 20px;
			height: 20px;
			background-size: 100% 100%;
			background-repeat: no-repeat;
			background-image: url("../img/icon/right.svg");
			opacity: 0.7;
			position: relative;
			top: -2px;
			margin-right: 4px;
		}

		&.-custom {
			&:after {
				content: "CUSTOM";
				display: inline;
				.font-size-label;
				color: @green;
				margin-left: 0.5rem;

				@media @vp-sm-down {
					.font-size(10);
				}
			}
		}

		&.-active {
			&:before {
				background-image: url("../img/icon/down.svg");
			}

			+ .answer { display: block; }
		}
	}

	.answer {
		.fw-regular;
		padding-left: 1.5rem;
		padding-top: 0.5rem;

		> .subheading {
			padding-left: 28px;
			margin-top: 0.5rem;
			margin-bottom: 0.5rem;
			font-style: italic;
		}

		> .options {
			padding-left: 1.5rem;
			margin: 0;
			margin-top: 0.5rem;
			margin-bottom: 0.5rem;

			> li {
				list-style: none;
				position: relative;
				
				 + li { margin-top: 0.5rem; }
			}

			&.-radios,
			&.-checkboxes,
			&.-select {
				> li {
					padding-left: 28px;
					position: relative;

					&:before {
						content: "";
						display: block;
						position: absolute;
						left: 0;
						top: 4px;
						width: 16px;
						height: 16px;
						background: @brand-light;
					}
				}
			}

			&.-radios {
				li:before { border-radius: 50%; }
			}

			&.-checkboxes {
				li:before { border-radius: 2px; }
			}

			&.-select {
				> li {
					&:before {
						background: none;
						content: "—";
						display: inline-block;
						top: 0;
					}

					+ li { margin-top: 0.25rem; }
				}
			}

			&.-textbox,
			&.-textline {
				padding-left: 28px;
				position: relative;
			}
		}
	}

	+ .footer-actions {
		@media @vp-sm-down {
			margin-top: 1rem;
		}

		// Handle wrapping of buttons for small screens
		@media (max-width: 550px) {
			margin-top: 0;

			> .button-group.-left {
				width: 100%;
				margin-bottom: 1rem;
			}
		}
	}
}