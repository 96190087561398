.groups-added {
	> .ui-heading {
		margin-bottom: 0.25rem;
	}

	> .groups {
		padding-left: 0;
		margin: 0;

		> li {
			display: flex;
			justify-content: space-between;
			list-style: none;

			+ li { margin-top: 0.5rem; }

			> .group {
				margin-right: 0.5rem;
			}

			> .remove {
				.font-size-body-small;
				color: @brand-50;
				background-image: none;
				margin-top: unit(( 4 / @fs-body-small ), em);

				&:hover { color: @brand-70; }
			}
		}
	}
}