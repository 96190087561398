.report-tiles {
	display: flex;
	flex-wrap: wrap;

	> .loading-container { width: 100%; }
	
	> .errors {
		width: 100%;
		.col-gutters-responsive;
		padding-top: 1rem;
		padding-bottom: 1rem;
	}

	> .report-tile {
		margin-bottom: (@grid-col-gutter * 2);
		flex-grow: 0;
		flex-shrink: 0;
		width: 100%;
		
		@media @vp-lg-up {
			.cols(1, 3);
		}

		@media @vp-md {
			.cols(1, 2);
		}
	}
}