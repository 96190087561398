.loading-spinner {
	width: 80px;
	height: 80px;
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	animation: loading-fadein 0.3s forwards;
	animation-delay: 0.2s;
	opacity: 0;

	> .spinner {
		width: 100%;
		height: 100%;
		.hide-text;
		background-image: url("../img/logo-icon.png");
		background-size: 100% 100%;
		background-repeat: no-repeat;
		transform: rotate(0deg);
		animation: loading-spinner 2s infinite;
	}

	&.-inline,
	&.-centred {
		vertical-align: middle;
		position: relative;
		width: 24px;
		height: 24px;
		top: 0;
		left: 0;
		transform: none;
		
		&.-large {
			height: 48px;
			width: 48px;
		}
		
		> .spinner {
			background-image: url("../img/logo-icon-small.png");
		}
		
	}
	
	&.-centred {
		display: block;
		margin-left: auto;
		margin-right: auto;
	}
	
	&.-inline {
		display: inline-block;
	}
}

@keyframes loading-spinner {
	0%   { transform: rotate(0deg); }
	100% { transform: rotate(360deg); }
}

@keyframes loading-fadein {
	0%   { opacity: 0; }
	100% { opacity: 1; }
}