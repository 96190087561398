.narrow-form {
	+ .narrow-form { margin-top: 3rem; }

	> .subheading-text.-small {
		margin-bottom: 0.75rem;
	}

	> .form-field {
		padding-bottom: 1rem;
	}

	&.-csvupload {
		> input[type=file] + div {
			border-radius: 8px !important;
			border-color: @border !important;
		}

		> .note {
			.font-size-body-small;
			margin-top: 1rem;
			color: @body-light;

			code {
				background: @background;
			}
		}
	}

	&.-downloads {
		> .form-field {
			padding-bottom: 0.5rem;

			&:last-child { padding-bottom: 0; }
		}
	}
}