.chart-key {
	> .values {
		display: flex;
		justify-content: center;
		padding-left: 0;
		list-style: none;
		margin: 0;

		> .value {
			position: relative;
			.font-size(10);
			.spaced-uppercase(10);
			.fw-bold;
			color: @brand-70;
			line-height: (16 / 10);
			padding-left: 16px;

			+ .value { margin-left: 1rem; }

			> svg {
				display: block;
				width: 12px;
				height: 12px;
				border-radius: 6px;
				position: absolute;
				top: 2px;
				left: 0;
				overflow: hidden;
			}

			&.-poor > svg { fill: @red; }
			&.-good > svg { fill: @yellow; }
			&.-great > svg { fill: @green; }
			&.-current > svg { fill: @brand; }
			&.-comparison > svg { fill: fade(@brand, 20%); }
			&.-normative > svg { fill: @comparison-green; }
			&.-background > svg { fill: fade(@brand, 20%); }
		}
	}

	&.-small {
		> .values {
			> .value {
				.font-size(8);
				.spaced-uppercase(8);
				line-height: (12 / 8);
				padding-left: 14px;

				+ .value { margin-left: 1rem; }

				> svg {
					width: 10px;
					height: 10px;
					border-radius: 5px;
					top: 1px;
				}
			}
		}
	}
}