.resource-summaries {
	> .summary {
		+ .summary {
			margin-top: 3rem;
		}

		> .subheading-text {
			margin-bottom: 1rem;
		}

		> .articles {
			margin-top: 2rem;

			+ .subheading-text {
				margin-top: 3rem;
			}

			> .content-teaser {
				+ .content-teaser {
					margin-top: 1.5rem;

					&.-minimal {
						margin-top: 1rem;						
					}
				}

			}
		}
	}
}