.tag-set {
	list-style: none;
	padding-left: 0;
	display: flex;
	margin: 0;
	align-items: center;

	> .tag {
		background: white;
		border-radius: 2px;
		.font-size-body-small;
		line-height: 24px;
		margin-left: 0.25rem;
		padding-left: 0.25rem;
		padding-right: 0.25rem;
		display: flex;

		> .remove {
			border: none;
			display: block;
			text-decoration: none;
			cursor: pointer;
			background: transparent;
			appearance: none;
			font-family: inherit;
			.hide-text;
			width: 12px;
			height: 24px;
			line-height: 1;
			position: relative;
			opacity: 0.5;
			transform: translateX(2px);

			&:hover { opacity: 1; }

			&:after {
				content: "";
				display: block;
				width: 12px;
				height: 12px;
				background-image: url("../img/remove-tag.svg");
				background-size: 100% 100%;
				background-repeat: no-repeat;
				position: absolute;
				top: 50%;
				left: 50%;
				margin-left: -6px;
				margin-top: -6px;
			}
		}
	}
}