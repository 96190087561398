/* Variables & Helpers
------------------------------------------------------------------------------*/
@import "core/constants";
@import "core/mixins";
@import "core/helpers";

/* Vendor
------------------------------------------------------------------------------*/
@import (less) "../vendor/normalize.css/normalize.css";
@import (less) "../vendor/react-datetime/react-datetime.css";
@import (less) "~react-toastify/dist/ReactToastify.css";


/* Core
------------------------------------------------------------------------------*/
// @import "core/fonts";
@import "core/scaffolding";

/* Components
------------------------------------------------------------------------------*/
@import "components/answers-list";
@import "components/arc-chart";
@import "components/article-page";
@import "components/aside-text";
@import "components/banner";
@import "components/bar-chart";
@import "components/barchart-collection";
@import "components/barchart-single";
@import "components/breadcrumb-bar";
@import "components/button-group";
@import "components/button-link";
@import "components/buttoned-field";
@import "components/chart-key";
@import "components/comparison-key";
@import "components/content-cols";
@import "components/content-header";
@import "components/content-teaser";
@import "components/dashboard-panel";
@import "components/dashboard-panels";
@import "components/data-table";
@import "components/debug";
@import "components/dialog-modal";
@import "components/edit-participants";
@import "components/editable-section";
@import "components/error-message";
@import "components/fileuploader";
@import "components/filter-options";
@import "components/footer-actions";
@import "components/form-field";
@import "components/form-input";
@import "components/form-row";
@import "components/groups-added";
@import "components/icon-grid";
@import "components/icon-text";
@import "components/icon-svg";
@import "components/icon-unit";
@import "components/inline-option";
@import "components/keyboard-nav";
@import "components/knowledge-base";
@import "components/link-lists";
@import "components/loading-container";
@import "components/loading-spinner";
@import "components/media-element";
@import "components/menu-toggle";
@import "components/modal-contents";
@import "components/modal-window";
@import "components/narrow-form";
@import "components/no-participants";
@import "components/option-inputs";
@import "components/overview-columns";
@import "components/overview-section";
@import "components/pagination-block";
@import "components/participant-summary";
@import "components/participant-groups";
@import "components/participated-surveys";
@import "components/permissions-table";
@import "components/personal-results";
@import "components/popup-menu";
@import "components/privacy-notice";
@import "components/progress-bar";
@import "components/question-table";
@import "components/quill";
@import "components/rdtPicker";
@import "components/recharts-wrapper";
@import "components/report-filtering";
@import "components/report-overview";
@import "components/report-tile";
@import "components/report-tiles";
@import "components/resource-summaries";
@import "components/resources-page";
@import "components/result-details";
@import "components/results-block";
@import "components/ring-chart";
@import "components/scale-chart";
@import "components/scale-key";
@import "components/schedule-date";
@import "components/side-menu";
@import "components/signin-form";
@import "components/site-body";
@import "components/site-header";
@import "components/site-logo";
@import "components/site-nav";
@import "components/site-footer";
@import "components/statistic-highlight";
@import "components/status-slug";
@import "components/survey-link";
@import "components/survey-overview";
@import "components/report-header";
@import "components/survey-introduction";
@import "components/survey-question";
@import "components/table-header";
@import "components/tag-set";
@import "components/toast-container";
@import "components/two-factor";
@import "components/type";

/* Screens
------------------------------------------------------------------------------*/
@import "screens/accounts";
@import "screens/personalreport";
@import "screens/print";
@import "screens/report";
@import "screens/surveys";

.site-header,
.site-body,
.site-footer,
.width-limit.-pageboundaries,
.site-grid.-pageboundaries {
	padding-left: @page-gutters;
	padding-right: @page-gutters;
	
	@media @vp-md {
		padding-left: @page-gutters-md;
		padding-right: @page-gutters-md;
	}
	
	@media @vp-sm-down {
		padding-left: @page-gutters-sm;
		padding-right: @page-gutters-sm;
	}
}

.width-limit {
	box-sizing: border-box;
	.font-smoothing;

	&.-site {
		max-width: @constraint-site;
		margin-left: auto;
		margin-right: auto;
	}

	&.-content {
		max-width: @constraint-content;
		margin-left: auto;
		margin-right: auto;
	}
	
	&.-report {
		max-width: @constraint-report;
		margin-left: auto;
		margin-right: auto;

		@media @vp-lg-up {
			width: @constraint-report;
		}
	}

	&.-survey,
	&.-narrow {
		max-width: @constraint-survey;
		margin-left: auto;
		margin-right: auto;
	}
	
	&.-article {
		max-width: @constraint-article;
		margin-left: auto;
		margin-right: auto;
	}

	&.-modal {
		max-width: @constraint-content;
		margin-left: auto;
		margin-right: auto;
	}
}

/*
  This will hide the focus indicator if the element receives focus via the mouse,
  but it will still show up on keyboard focus.
*/
.js-focus-visible :focus:not(.focus-visible) {
	outline: none;
}

@import "components/_demoblock";