.modal-open {
	// Applied to <body>
	overflow: hidden;
	
	.modal-window {
		opacity: 1;
		
		> .container {
			opacity: 1;
			transform: scale(1);
		}
	}
}

.modal-window {
	position: fixed;
	z-index: 2000;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	display: flex;
	background: @brand-modal-bg;
	overflow-y: auto;
	-webkit-overflow-scrolling: touch;
	opacity: 0;
	
	@media @vp-md-down {
		overflow-y: scroll; /* has to be scroll */
		-webkit-overflow-scrolling: touch;
	}
		
	> .container {
		position: relative;
		z-index: 1;
		width: 100%;
		max-height: 100%;
		opacity: 0;
		transform: scale(0.95);
		transition: all 0.3s @easeOutQuart;
		
		@media @vp-md-up {
			align-self: center;
			padding-right: @page-gutters;
			padding-left: @page-gutters;
		}
		
		> .width-limit {
			@media @vp-sm-down {
				padding: 10px;
			}
			
			@media @vp-md-up {
				padding-top: 3rem;
				padding-bottom: 3rem;
			}

			> .contents {
				background: white;
				box-shadow: 0 4px 24px rgba(0, 0, 0, 0.5);
				position: relative;
				height: auto;
				// overflow: hidden;
				.col-gutters;
				border-radius: @border-radius-large;

				&:before {
					content: '';
					position: absolute;
					left: 0;
					top: 0;
					right: 0;
					height: 50px;
					background: linear-gradient(to bottom, white, rgba(white, 0));
					z-index: 5;
					border-top-left-radius: @border-radius-large;
					border-top-right-radius: @border-radius-large;
					pointer-events: none;
				}
				
				@media @vp-md-up {
					margin-left: auto;
					margin-right: auto;
					.cols(10);
				}
				
				@media @vp-md {
					.cols(10);
				}
				
				&.-narrow {
					width: auto;
					max-width: 350px;
					margin-left: auto;
					margin-right: auto;
					height: auto;
					max-height: 50vh;
				}
				
				&.-medium {
					width: auto;
					max-width: 700px;
					margin-left: auto;
					margin-right: auto;
				}

				&.-large {
					width: auto;
					// max-height: 70vh;
					// height: 70vh;
					// max-width: calc( percentage((10/@grid-cols-total)) + (@grid-col-gutter * 2) );
					padding: 0;
					overflow: hidden;
				}
				
				> .close {
					position: absolute;
					top: 1rem;
					right: 1rem;
					.hide-text;
					width: 24px;
					height: 24px;
					cursor: pointer;
					z-index: 10;
					
					@media @vp-sm-down {
						top: 0.5rem;
						right: 0.5rem;
					}
		
					&:before,
					&:after {
						content: "";
						display: block;
						background: @grey-dark;
						position: absolute;
						top: 50%;
						left: 50%;
						border-radius: 100px;
					}
		
					&:before {
						width: 2px;
						height: 24px;
						transform: translate(-50%, -50%) rotate(45deg);
					}

					&:after {
						width: 2px;
						height: 24px;
						transform: translate(-50%, -50%) rotate(-45deg);
					}
					
					&:hover {
						&:before,
						&:after {
							background: @brand-darker;
						}
					}
				}
			}
		}
	}
}

.overflow-scrollbar-measure { // From twitter bootstrap
	position: absolute;
	top: -9999px;
	width: 50px;
	height: 50px;
	overflow: scroll;
}
