.answers-list {
	margin-bottom: 1rem;

	> .answer {
		display: flex;
		justify-content: space-between;
		padding-top: 0.5rem;
		padding-bottom: 0.5rem;

		> .text {
			// .fw-bold;
		}

		.options {
			white-space: nowrap;
			flex-shrink: 0;

			> .icon-unit {
				cursor: pointer;
				
				+ .icon-unit { margin-left: 0.5rem; }

				&:hover { opacity: 1; }

				&.-order { cursor: move; }
			}
		}
	}
}