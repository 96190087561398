// Custom styles to overide defaults of vendor component
// https://github.com/jakehartnell/react-images-upload#readme

.fileUploader {
	> .fileContainer {
		box-shadow: none;
		border: 2px solid @field-bg;
		.sans-serif;

		> p,
		.errorMessage { .font-size-body-small; }

		.chooseFileButton {
			border: none;
			display: inline-block;
			vertical-align: middle;
			text-decoration: none;
			text-transform: uppercase;
			cursor: pointer;
			background: transparent;
			appearance: none;
			font-family: inherit;
			.button-size-default;
			.fw-black;
			border-radius: 5px;
			color: fade(@brand, 80%);
			background: white;
			border: 2px solid @brand-light;
			padding: unit((6 / 14), em) 1.5em unit((6 / 14), em);

			&:focus {
				outline: none;
				box-shadow: 0 0 3px @brand;
			}

			&:hover {
				border-color: @brand-50;
				color: @brand;
	
				&[disabled] { border-color: @brand-light; }
			}
		}

		.uploadPictureContainer {
			width: 50%;
			padding: 0;
			box-shadow: none;
			margin-bottom: 0;
		}

		.deleteImage {
			.hide-text;
			background-color: @red;
			width: 2rem;
			height: 2rem;

			&:before,
			&:after {
				content: "";
				display: block;
				background: white;
				position: absolute;
				top: 50%;
				left: 50%;
				width: 1rem;
				height: 2px;
			}

			&:before {
				transform: translate(-50%, -50%) rotate(-45deg);
			}

			&:after {
				transform: translate(-50%, -50%) rotate(45deg);
			}
		}
	}
}