.scale-chart {
	height: 12px;
	display: flex;

	> .bar {
		height: 100%;
		width: 100%;
		border-radius: 8px;
		background-image: linear-gradient(90deg,
			#D73F38 0%,
			#D73F38 15%, 
			#EAAF10 30%, 
			#EECB06 40%, 
			#F1DC00 45%, 
			#F1DC00 55%, 
			#E1D808 60%, 
			#B7CE1F 70%, 
			#50B555 85%, 
			#50B555 100%
		);
		background-repeat: repeat-y;
		background-size: 100%;
		position: relative;

		> .indicator {
			position: absolute;
			width: 0px;
			height: 100%;
			top: 0;
			left: 0;

			&:before {
				content: "";
				display: block;
				position: absolute;
				top: 50%;
				left: 0;
				width: 16px;
				height: 28px;
				transform: translate(-50%, -50%);
				background-image: url("../img/scale-indicator.svg");
				background-size: 100% 100%;
				background-repeat: no-repeat;
			}
		}
	}
}