.option-inputs {
	margin: 0;
	padding-left: 0;
	
	> .option {
		list-style: none;
		margin: 0;

		+ .option { margin-top: 1rem; }
		
		> .label {
			margin-bottom: 0;
			color: @brand;
			display: block;

			&.-bold { .fw-bold; }

			> .other {
				display: flex;

				> .label { padding-right: (@grid-col-gutter / 2); }

				> .form-input {
					flex-grow: 1;

					> .field {
						margin-top: -(unit((8 / 16), em));
						margin-bottom: -(unit((8 / 16), em));
					}
				}
			}
		}
		
		&.-radio {
			> .label {
				.option-input();
			}
		}

		&.-checkbox {
			> .label {
				.option-input();
			}
		}
	}

	&.-large {
		> .option {
			> .label {
				@media @vp-md-up {
					.font-size-body-large;
				}
			}

			+ .option { margin-top: 1.5rem; }

			&.-radio {
				> .label {
					padding-left: 36px;
					
					> .radio + .substitute {
						top: unit((13 / 18), em);
					}
				}
			}
	
			&.-checkbox {
				> .label {
					padding-left: 36px;
				}
			}
		}
	}

	&.-small {
		> .option {
			> .label {
				.font-size-body-small;
			}

			+ .option { margin-top: 1rem; }

			&.-radio {
				> .label {
					.option-input(@size: 16px, @text-gap: 6px);
					
					> .radio + .substitute {
						top: unit((10 / 14), em);
					}
				}
			}
	
			&.-checkbox {
				> .label {
					.option-input(@size: 16px, @text-gap: 6px);

					> .checkbox + .substitute {
						top: unit((10 / 14), em);

						&:after {
							width: 10px;
							height: 5px;
							border-bottom-width: 2px;
							border-left-width: 2px;
							transform: translate(-5px, -3px) rotate(-50deg);
						}
					}
				}
			}
		}
	}
	
	&.-inline {
		> .option {
			display: inline-block;
			margin-right: 1.5em;
		}
	}
	
	&.-single {
		> .option {
			margin-top: 0;
		}
	}

	&.-notapplicable {
		margin-bottom: 2rem;
		.col-gutters-responsive;
	}

	&.-scale {
		display: flex;
		justify-content: space-between;
		width: 100%;
		position: relative;

		&:before {
			.positioned-border;
			top: 50%;
			height: 1px;
			background-color: @border;

			.-incomplete & {
				background-color: @red;
			}
		}

		> .option {
			margin: 0;

			&:first-child { margin-left: -12px; }
			&:last-child { margin-right: -12px; }

			> .label {
				display: block;
				width: 24px;
				height: 24px;
				padding-left: 24px;

				> .text { .sr-only; }

				.substitute {
					.-incomplete & {
						border-color: @red !important;
					}
				}
			}
		}

		&.-disabled {
			&:before {
				background-color: mix(@brand-light, white, 25%);
			}

			> .option.-radio > .label > .radio:disabled {
				+ .substitute {
					background-color: lighten(@field-bg, 2%);
					border: 1px solid lighten(@border, 10%);
					opacity: 1;
				}

				&:checked + .substitute:after {
					background: @brand-50;
				}
			}
		}
	}
}