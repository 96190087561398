.dashboard-panel {
	.col-gutters-responsive;

	> .content {
		.shadowed-block;
		padding: 1.5rem @grid-col-gutter;
		height: 100%;
		position: relative;
		display: flex;
		flex-direction: column;

		> .body-text { margin-bottom: 1.25rem; }
		
		> .footer {
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;

			> .body-text {
				margin: 0;

				@media @vp-dashboard-without-columns {
					width: 100%;
					margin-bottom: 0.5rem;
				}

				> .status-slug { margin-right: 0.5rem; }
			}
			
			> .button-link {
				margin-left: auto;
				flex-shrink: 0;
				align-self: flex-end;

				@media @vp-xs {
					width: 100%;
					text-align: center;
				}
			}
		}

		> .subheading-text {
			margin-bottom: 0.25rem;

			+ .footer {
				margin-top: auto;
				padding-top: 0.75rem;

				> * {
					margin-top: 0.5rem;
				}

				> .body-text { margin-right: 0.5rem; }
			}
		}
	}

	.progress {
		margin-bottom: 1.25rem;
		margin-top: auto;

		> .context {
			@media @vp-dashboard-with-columns {
				position: absolute;
				left: @grid-col-gutter;
				bottom: 1.5rem;
			}

			@media @vp-dashboard-without-columns {
				display: flex;
				justify-content: space-between;
				align-items: center;
				margin-top: 0.5rem;
			}

			> .ui-heading {
				.font-size(8);
				.spaced-uppercase(8);
				line-height: (12 / 8);
				margin-bottom: 0.25rem;
				
				@media @vp-dashboard-without-columns {
					margin-bottom: 0;
				}
			}

			> .chart-key > .values{
				justify-content: flex-start;
			}
		}
	}

	&.body-text {
		margin-bottom: 2rem;
	}

	&.-empty {
		margin-bottom: 2rem;

		@media @vp-dashboard-with-columns {
			margin-bottom: 4rem;	
		}

		> .content {
			text-align: center;
			padding-top: 4rem;
			padding-bottom: 4rem;

			> .button-link {
				margin-top: 2rem;
				align-self: center;
			}
		}
	}
}