.permissions-table {
	@media @vp-sm-down {
		border-bottom-width: 2px;
		border-top: 2px solid @background;

		> thead { display: none; }
		> tbody {
			> tr {
				display: flex;
				flex-wrap: wrap;

				+ tr {
					margin-top: 1.25rem;
					border-top: 1px solid @background;
					padding-top: 0.5rem;

					&:last-child { padding-bottom: 1.25rem; }
				}

				> td {
					width: 50%;
					padding-left: 0;
					padding-right: 0;
					padding-bottom: 0 !important;
					
					&.username {
						width: 100%;
						.fw-bold;

						+ .email { padding-top: 0 !important; }
					}
					
					&.email {
						width: 100%;
					}

					@remove-cell-width: 48px;
					
					&.permissions {
						width: ~"calc(100% - @{remove-cell-width})";
					}

					&.remove {
						width: @remove-cell-width;

					}
				}
			}
		}
	}
}