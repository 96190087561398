.article-page {
	.width-limit {
		padding-left: (@page-gutters * 2);
		padding-right: (@page-gutters * 2);
		box-sizing: content-box;

		@media @vp-sm-down {
			padding-left: (@page-gutters);
			padding-right: (@page-gutters);
		}
	}

	.title {
		&.-link {
			cursor: pointer;
		}
	}

	> .header {
		background: @background;
		padding-top: 2rem;
		padding-bottom: 1rem;
		position: relative;

		.subheading { margin: 0; }

		.close {
			position: absolute;
			top: 0.5rem;
			right: 0.5rem;
		}
	}

	> .body {
		padding-top: 3rem;
		padding-bottom: 3rem;

		@media @vp-sm-down {
			padding-top: 2rem;
		}
	}

	.body-text.-hero {
		@media @vp-sm-down {
			.font-size-body;
		}

		> .category {
			.font-size-body;
			.fw-bold;
			display: inline-block;
			margin-bottom: 0.25rem;
			position: relative;

			@media @vp-sm-down {
				.font-size-body-small;
			}

			&:before {
				content: "";
				.hide-text;
				display: block;
				width: 18px;
				height: 18px;
				background-image: url("../img/icon/left.svg");
				background-size: 100% 100%;
				background-repeat: no-repeat;
				opacity: 0.7;
				position: absolute;
				left: -20px;
				top: 3px;
			}
		}

		> .headline-text {
			margin-bottom: 1.5rem;

			@media @vp-sm-down {
				.font-size-subheading;
			}
		}

		> .media-element, .media {
			margin-bottom: 2rem;
			margin-top: 2rem;

			&.-image {
				margin-bottom: 0;
			}

			> .image {
				> img {
					display: block;
					max-width: 100%;
					height: auto;
				}
				&.-portrait {
					> img {
						position: relative;
						height: auto;
						max-height: 660px;
						width: auto;
						max-width: 100%;
						margin-left: auto;
						margin-right: auto;
					}
				}
			}
		}

		> .related {
			margin-top: 3rem;
			.title {
				&.-link {
					text-decoration: underline;
				};
			}

			.media {
				margin-top: 0;
			}

			> .subheading-text {
				margin-bottom: 1.5rem;
			}
			
			> .articles {
				> .content-teaser {
					+ .content-teaser {
						margin-top: 1.5rem;
					}
				}
			}
		}
	}
}