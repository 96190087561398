.dashboard-panels {
	margin-bottom: 2rem;

	@media @vp-dashboard-with-columns {
		display: flex;
		flex-wrap: wrap;
		margin-bottom: 4rem;	
	}

	> .dashboard-panel {
		margin-bottom: @grid-col-gutter;
		
		@media @vp-dashboard-with-columns {
			width: 50%;
			margin-bottom: (@grid-col-gutter * 2);
		}
	}
}