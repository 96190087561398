.aside-text {
	.col-gutters-responsive;
	position: relative;
	
	@media @vp-md-up {
		&:before {
			content: "";
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			height: 100%;
			width: 1px;
			background: @border;
		}
	}

	> .icon-text {
		margin-bottom: 1em;
		color: @brand-70;

		&:before {
			opacity: 0.5;
		}
	}

	> .body-text {

	}
}